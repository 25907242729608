import React, { useState, useEffect } from 'react';
import { Table, Card, Button, Space, Modal, message, Input } from 'antd';
import { PlusOutlined, EditOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import MainLayout from '../../components/Layout/MainLayout';
import ProjectForm from './ProjectForm';
import projectService from '../../services/projectService';
import { Link, Navigate, useNavigate, useSearchParams } from 'react-router-dom';

const Projects = () => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedProjectId, setSelectedProjectId] = useState(searchParams.get('projectId'));
  const [searchQuery, setSearchQuery] = useState('');

  const navigate = useNavigate()

  const filteredProjects = projects.filter(project =>
    project.title.toLowerCase().includes(searchQuery.toLowerCase()) || project.projectNumber.toLowerCase().includes(searchQuery.toLowerCase())
  );
  useEffect(() => {
    fetchProjects();
  }, []);

  const fetchProjects = async () => {
    setLoading(true);
    try {
      const data = await projectService.getAllProjects();
      setProjects(data);
    } catch (error) {
      message.error('Failed to fetch projects');
    } finally {
      setLoading(false);
    }
  };

  const handleCreate = () => {
    setSelectedProject(null);
    setModalVisible(true);
  };

  const handleEdit = (project) => {
    console.log('Editing project:', project);
    setSelectedProject(project);
    setModalVisible(true);
  };

  const handleDelete = async (id) => {
    try {
      await projectService.deleteProject(id);
      message.success('Project deleted successfully');
      fetchProjects();
    } catch (error) {
      message.error('Failed to delete project');
    }
  };

  const columns = [
    {
      title: 'Reference ID',
      dataIndex: 'projectNumber',
      key: 'projectNumber',
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (text, record) => (
        <span onClick={() => handleProjectCardSelect(record.id, record.currency)} style={{ cursor: 'pointer', color: 'blue' }}>
          {text}
        </span>
      ),
    },
    {
      title: 'Customer Name',
      dataIndex: 'customerName',
      key: 'customerName',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <Button
            type="link"
            icon={<EyeOutlined />}
            onClick={() => handleEdit(record)}
          >
            View Project Details
          </Button>
          {/* <Button
            type="link"
            danger
            icon={<DeleteOutlined />}
            onClick={() => handleDelete(record.id)}
          >
            Delete
          </Button>
          <Link to={`/projects/${record.id}`}>
            <Button type="link" icon={<EyeOutlined />}>
              View Details
            </Button>
          </Link> */}
        </Space>
      ),
    },
  ];

  const handleProjectCardSelect = (projectId, currency) => {
    if (selectedProjectId === projectId) {
      // If the same project is selected again, remove the projectId from the URL
      searchParams.delete('projectId');
      searchParams.delete('currency');
      setSearchParams(searchParams);
      setSelectedProjectId(null); // Reset the selected project ID
    } else {
      // Set the projectId in the URL
      searchParams.set('projectId', projectId);
      searchParams.set('currency', currency);
      setSearchParams(searchParams);
      setSelectedProjectId(projectId); // Update the selected project ID
    }
  };

  const navigateToProjectActivities = (id) => {
    setTimeout(() => {
      navigate(`/projects/${id}/`);
    }, 1000);
  }

  return (
    <MainLayout>
      <Card
        title="Projects"
        extra={
          <Button type="primary" icon={<PlusOutlined />} onClick={handleCreate}>
            New Project
          </Button>
        }
      >
        <Input
          placeholder="Search by title"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          style={{ marginBottom: '20px' }}
        />
        <Table
          columns={columns}
          dataSource={filteredProjects}
          rowKey="id"
          loading={loading}
          pagination={ { pageSize: 9 }}
          rowClassName={(record) => 
            record.id === selectedProjectId ? 'selected-row' : ''
          }
          onRow={(record) => ({
            onClick: () => handleProjectCardSelect(record.id, record.currency),
          })}
        />
      </Card>
      <Modal
        title={selectedProject ? 'Edit Project' : 'Create Project'}
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
        width={800}
      >
        <ProjectForm
          project={selectedProject}
          onSuccess={(id) => {
            setModalVisible(false);
            fetchProjects();
            navigateToProjectActivities(id)
          }}
          onCancel={() => setModalVisible(false)}
        />
      </Modal>
    </MainLayout>
  );
};

export default Projects;