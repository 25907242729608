import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000/api';

const authService = {
  login: async (credentials) => {
    const response = await axios.post(`${API_URL}/auth/login`, credentials);
    return response.data;
  },

  register: async (userData) => {
    const response = await axios.post(`${API_URL}/auth/register`, userData);
    return response.data;
  },

  validateToken: async (token) => {
    const response = await axios.get(`${API_URL}/auth/validate`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  },

  requestPasswordReset: async (email) => {
    const response = await axios.post(`${API_URL}/auth/request-password-reset`, { email });
    return response.data;
  },

  resetPassword: async ({email,token, password}) => {
    const response = await axios.post(`${API_URL}/auth/reset-password`, { email,token, password });
    return response.data;
  },
};

export default authService;