import { useState } from 'react';
import { Button, Card, Form, Input, message as antdMessage } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import authService from '../../services/authService';

const ForgotPassword = () => {
    const [form] = Form.useForm();
    const [message, setMessage] = useState('');

    const handleSubmit = async (values) => {
        try {
            const response = await authService.requestPasswordReset(values.email);
            response && antdMessage.success('Reset link sent to your email.');
        } catch (error) {
            const errorMsg = error.response?.data?.message ;
            setMessage(errorMsg);
            antdMessage.error(errorMsg);
        }
    };

    return (
        <div style={{
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: '#f0f2f5'
        }}>
            <Card style={{ width: 400 }}>
                <div style={{ textAlign: 'center', marginBottom: 24 }}>
                    <h1>Forgot Password</h1>
                </div>
                <Form form={form} onFinish={handleSubmit}>
                    <Form.Item
                        name="email"
                        rules={[
                            { required: true, message: 'Please enter your email' },
                            { type: 'email', message: 'Please enter a valid email' }
                        ]}
                    >
                        <Input
                            prefix={<UserOutlined />}
                            placeholder="Email"
                            size="large"
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" block size="large">
                            Send Reset Link
                        </Button>
                    </Form.Item>
                </Form>
                {message && (
                    <div style={{ textAlign: 'center', marginTop: 10, color: 'red' }}>
                        {message}
                    </div>
                )}
            </Card>
        </div>
    );
};

export default ForgotPassword;
