import React, { useEffect, useState } from 'react';
import { Button, Layout, Menu } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  DashboardOutlined,
  DollarOutlined,
  HddOutlined,
  ProjectOutlined,
  SettingOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import { useAuth } from '../../context/AuthContext';
import projectService from '../../services/projectService';

const { Header, Sider, Content } = Layout;

const MainLayout = ({ children }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams(); // Use useSearchParams to get the query parameters
  const projectId = searchParams.get('projectId'); // Get projectId from query parameters
  const currency = searchParams.get('currency'); // Get currency from query parameters
  const { logout } = useAuth();
  const [projectName, setProjectName] = useState(null);

  useEffect(() => {
    const fetchProject = async () => {
      if (projectId) {
        try {
          const project = await projectService.getProjectById(projectId);
          setProjectName(project.title);
        } catch (error) {
          console.error("Error fetching project:", error);
          setProjectName(null);
        }
      }
    };

    fetchProject(); // Call the async function

  }, [projectId]);

  const menuItems = [
    // {
    //   key: 'shipyards',
    //   icon: <TeamOutlined />,
    //   label: 'Shipyards',
    //   onClick: () => navigate('/shipyards'),
    // },
    // {
    //   key: 'task-templates',
    //   icon: <HddOutlined />,
    //   label: 'Tasks Templates',
    //   onClick: () => navigate('/task-templates'),
    // },
    {
      key: 'projects',
      icon: <ProjectOutlined />,
      label: 'Projects',
      onClick: () => navigate('/projects'),
    },
  ];

  const siderMenuItems = [
    projectName
    ? {
        key: "project-name",
        label:<span style={{ fontWeight: "bold", color: "#1890ff" }}>{projectName}</span>, 
        disabled: true, 
      }
    : null,
    {
      key: 'dashboard',
      icon: <DashboardOutlined />,
      label: 'Gantt Chart',
      onClick: () => navigate(`/dashboard?projectId=${projectId}&currency=${currency}`),
    },
    {
      key: 'activity-template',
      icon: <HddOutlined />,
      label: 'Activity Template',
      onClick: () => navigate(`/projects/${projectId}`),
    },
    {
      key: 'Daily-input',
      icon: <ProjectOutlined />,
      label: 'Daily input',
      onClick: () => navigate(`/daily-input?projectId=${projectId}&currency=${currency}`), // Navigate to project details
    },
    {
      key: 'deliverables-schedule',
      icon: <ProjectOutlined />,
      label: 'Deliverables Schedule',
      onClick: () => navigate(`/deliverables-schedule?projectId=${projectId}&currency=${currency}`), // Navigate to project details
    },
    {
      key: 'costs',
      icon: <DollarOutlined />,
      label: 'Costs',
      onClick: () => navigate(`/costs/${projectId}`), // Navigate to project details
    },
  ];
  const handleLogout = () => {
    logout(); // Call the logout function
  };
  return (
    <Layout style={{ minHeight: '100vh' }}>
      {projectId && (
        <Sider
          style={{
            width: projectId ? 200 : 0, // Set width to 0 when hidden
            overflow: 'hidden',
            paddingTop: '65px',
            background: '#fff',
          }}
          className={`custom-sider ${projectId ? 'visible' : 'hidden'}`} // Add classes for animation
        >
          <Menu
            mode="inline"
            defaultSelectedKeys={['dashboard']}
            items={siderMenuItems}
            style={{ height: '100%', borderRight: 0 }}
          />
        </Sider>
      )}
      <Layout>
        <Header style={{ padding: 0 ,}}>
          <Menu theme="light" mode="horizontal" style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {menuItems.map(item => (
              <Menu.Item key={item.key} icon={item.icon} onClick={item.onClick}>
                {item.label}
              </Menu.Item>
            ))}
            <Menu.Item>
              <Button type="primary" onClick={handleLogout}>
                Logout
              </Button>
            </Menu.Item>
          </Menu>
        </Header>
        <Content style={{ margin: '24px', background: '#fff', padding: '24px' }}>
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default MainLayout;