import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { Form, Input, DatePicker, Select, InputNumber, Button, message, Modal } from 'antd';
import projectService from '../../services/projectService';
import taskTemplateService from '../../services/taskTemplateService';
import TaskTemplateModal from '../../components/TaskTemplate/TaskTemplateModal';
import assignedTaskTemplateService from '../../services/assignedTaskTemplateService';
import { Option } from 'antd/es/mentions';
import AssignedShipyardService from '../../services/assignedShipyardService';
import shipyardService from '../../services/shipyardService';
import ShipyardForm from '../Shipyards/ShipyardForm';
import ExcelProcessor from '../Test';

const { TextArea } = Input;
const { RangePicker } = DatePicker;

const ProjectForm = ({ project, onSuccess, onCancel }) => {
  const [form] = Form.useForm();
  const [taskTemplates, setTaskTemplates] = useState([]);
  const [shipyards, setShipyards] = useState([]);
  const [excelTasks, setExcelTasks] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newTaskTemplate, setNewTaskTemplate] = useState(null);
  const [selectedShipyard, setSelectedShipyard] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [creationRes, setCreationRes] = useState({})

  const excelProcessorRef = useRef(null);

  const handleTaskTemplateChange = (value) => {
    if (value === 'create') {
      openCreateTaskTemplateModal();
    } else if (value === 'excel' && excelProcessorRef.current) {
      excelProcessorRef.current.click();
    }
  };

  const handleSubmit = async (values) => {
    let projectId;
    try {
      // let uploadedTemplateId = localStorage.getItem("taskTemplateId"); 
      // let assignedShipyardId;
      // let assignedTemplateId // Declare assignedTemplateId outside the if blocks
      // if (selectedShipyard) {
      //   assignedShipyardId = await cloneShipyard(selectedShipyard.id);
      //   } else {
      //     assignedShipyardId = await cloneShipyard(values.assignedShipyardId);
      //   }
      // if (!uploadedTemplateId){
      // if (newTaskTemplate) {
      //     assignedTemplateId = await cloneTaskTemplate(newTaskTemplate.id);
      // } else {
      //   assignedTemplateId = await cloneTaskTemplate(values.assignedTaskTemplateId);
      // }
      // }
      const projectData = {
        ...values,
        startDate: values.dates[0].format('YYYY-MM-DD'),
        endDate: values.dates[1].format('YYYY-MM-DD'),
        // taskTemplateId: uploadedTemplateId,
        // assignedTaskTemplateId:assignedTemplateId, // Use the assignedTemplateId here
        // assignedShipyardId
      };

      if (project) {
        await projectService.updateProject(project.id, projectData);
        message.success('Project updated successfully');
      } else {
       let res = await projectService.createProject(projectData);
        projectId = res.id
        console.log(res)
        setCreationRes(res)
        message.success('Project created successfully');
      }
      onSuccess(projectId);
    } catch (error) {
      message.error('Failed to save project');
    }
  };

  // useEffect(() => {
  //   const fetchTaskTemplates = async () => {
  //     const templates = await taskTemplateService.getTaskTemplates();
  //     setTaskTemplates(templates);
  //   };
  //   const fetchShipyards = async () => {
  //     const shipyards = await shipyardService.getAllShipyards();
  //     setShipyards(shipyards);
  //   };
  //   fetchShipyards();
  //   fetchTaskTemplates();
  // }, []);

  const openCreateTaskTemplateModal = () => {
    setIsModalVisible(true);
  };

  useEffect(() => {
    return () => {
      form.resetFields();
    };
  }, [onCancel]);
  const handleModalClose = () => {
    form.resetFields(); // Reset all form fields to undefined
    setIsModalVisible(false);
  };

  const handleTaskTemplateCreated = (template) => {
    setNewTaskTemplate(template); // Store the newly created task template
    setTaskTemplates((prev) => [...prev, template]); // Add the new template to the list
    setIsModalVisible(false); // Close the modal
  };
  const handleShipyardCreated = (shipyard) => {
    setSelectedShipyard(shipyard);
    setShipyards((prev) => [...prev, shipyard]);
    form.setFieldsValue({ assignedShipyardId: shipyard.name });
    setModalVisible(false);
  }
  const cloneTaskTemplate = async (templateId) => {
    try {
      const newTemplate = await assignedTaskTemplateService.cloneAssignedTaskTemplate(templateId);
      return newTemplate.id; // Return the ID of the cloned template
    } catch (error) {
      message.error('Failed to clone New Shipyard');
      throw error; // Propagate the error
    }
  };
  const cloneShipyard = async (shipyardId) => {
    try {
      const newShipyard = await AssignedShipyardService.cloneAssignedShipyard(shipyardId);
      return newShipyard.id; // Return the ID of the cloned template
    } catch (error) {
      message.error('Failed to clone task template');
      throw error; // Propagate the error
    }
  };
  const getAssignedTaskTemplate = async (id) => {
    try {
      const template = await assignedTaskTemplateService.getAssignedTaskTemplateById(id);
      return template.title;
    } catch (error) {
      console.error('Error fetching assigned task template:', error);
      throw error;
    }
  }
  const getAssignedShipyard = async (id) => {
    try {
      if (!id) return null;
      const shipyard = await AssignedShipyardService.getAssignedShipyardById(id);
      return shipyard.name;
    } catch (error) {
      console.error('Error fetching assigned task template:', error);
      throw error;
    }
  }
  // useEffect(() => {
  //   const fetchAssignedTaskTemplate = async () => {
  //     if (project && project.assignedTaskTemplateId) {
  //       const title = await getAssignedTaskTemplate(project.assignedTaskTemplateId);
  //       form.setFieldsValue({ assignedTaskTemplateId: title }); // Set the title in the form field
  //     }
  //   };
  //   const fetchAssignedShipyard = async () => {
  //     if (project && project.assignedShipyardId) {
  //       const name = await getAssignedShipyard(project.assignedShipyardId);
  //       form.setFieldsValue({ assignedShipyardId: name }); // Set the title in the form field
  //     }
  //   };
  //   // fetchAssignedTaskTemplate();
  //   fetchAssignedShipyard();
  // }, [project]);

  const generateProjectNumber = () => {
    const prefixes = [
      'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z',
      'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'
    ];
    const randomPrefix = prefixes[Math.floor(Math.random() * prefixes.length)]; // Randomly select a prefix
    const randomNumber = Math.floor(Math.random() * 100000); // Generate a number between 0 and 99999
    const formattedNumber = String(randomNumber).padStart(5, '0'); // Pad with zeros to ensure it has 5 digits
    const result = randomPrefix + '-' + formattedNumber; // Combine random prefix and formatted number
    form.setFieldsValue({ projectNumber: result }); // Set the generated value in the form
  };
  const handleShipyardSelect = (id) => {
    const selected = shipyards.find(shipyard => shipyard.id === id);
    setSelectedShipyard(selected); // Set the entire shipyard object
    setModalVisible(true); // Open modal when shipyard is selected
  };

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        initialValues={
          project
            ? {
              ...project,
              dates: [moment(project.startDate), moment(project.endDate)]
            }
            : undefined
        }
      >
        <Form.Item
          name="title"
          label="Project Title"
          rules={[{ required: true, message: 'Please enter project title' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Project Number" required>
          <Input.Group compact>
            <Form.Item name="projectNumber" noStyle rules={[{ required: true, message: 'Please enter the project number' }]}>
              <Input style={{ width: 'calc(100% - 100px)' }} />
            </Form.Item>
            {!project && <Button type="primary" onClick={generateProjectNumber}>Generate</Button>}
          </Input.Group>
        </Form.Item>

        <Form.Item name="customerName" label="Customer's Name" rules={[{ required: true, message: 'Please enter the customer\'s name' }]}>
          <Input />
        </Form.Item>

        <Form.Item name="shipsClassification" label="Ships Classification">
          <Input />
        </Form.Item>

        <Form.Item name="currency" label="Currency" rules={[{ required: true, message: 'Please select a currency' }]}>
          <Select onChange={(value) => setSelectedCurrency(value)} placeholder="Select currency">
            <Option value="USD">USD</Option>
            <Option value="EUR">EUR</Option>
            <Option value="CAD">CAD</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="dates"
          label="Project Duration"
          rules={[{ required: true, message: 'Please select project duration' }]}
        >
          <RangePicker style={{ width: '100%' }} />
        </Form.Item>

        
        {/* <Form.Item
          label="Task Template"
          name="assignedTaskTemplateId"
          rules={[{ required: true, message: 'Please select task template' }]}
        >
          <Select placeholder="Select a task template" onChange={handleTaskTemplateChange} disabled={project}>
            <Select.Option value="excel">Upload an Excel File </Select.Option>
            <Select.Option value="create">Create Task Template</Select.Option>
          </Select>
        </Form.Item> */}

        {/* <Form.Item
          label="Shipyard"
          name="assignedShipyardId"
          rules={[{ required: true, message: 'Please select task template' }]}
        >
          <Select placeholder="Select a shipyard" onChange={handleShipyardSelect} disabled={project}>
            {shipyards.map(shipyard => (
              <Select.Option key={shipyard.id} value={shipyard.id} onClick={handleShipyardSelect}>
                {shipyard.name}
              </Select.Option>
            ))}
            <Select.Option value="create">Create Shipyard</Select.Option>
          </Select>
        </Form.Item> */}
        <Form.Item>
          <Button type="primary" htmlType="submit" block>
            {project ? 'Update Project' : 'Create Project'}
          </Button>
        </Form.Item>
      </Form>
      
      {/* <TaskTemplateModal
        isModalVisible={isModalVisible}
        handleModalClose={handleModalClose}
        setTemplates={setTaskTemplates}
        excelTasks={excelTasks}
        onTaskTemplateCreated={handleTaskTemplateCreated} // Pass the handler
        />  */}

      <Modal
        title={'Create Shipyard'}
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
        width={800}
      >
        <ShipyardForm
          shipyard={selectedShipyard}
          onSuccess={() => {
            setModalVisible(false);
          }}
          onCancel={() => setModalVisible(false)}
          onShipyardCreated={handleShipyardCreated}
          selectedCurrency={selectedCurrency}
        />
      </Modal>
      {/* <ExcelProcessor fileInputRef={excelProcessorRef} excelTasks={excelTasks} setExcelTasks={setExcelTasks}  /> */}
    </>
  );
};

export default ProjectForm;
