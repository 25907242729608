
/**  New component   */

import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import * as XLSX from 'xlsx';
import Modal from 'react-modal';
import headerExample from './header-example.png';
import taskTemplateService from '../../services/taskTemplateService';
import activityService from '../../services/activityService';
import manpowerService from '../../services/manpowerService';
import baselineService from '../../services/baselineService';
import { useNavigate } from 'react-router-dom';

// Default headers our system requires
const DEFAULT_HEADERS = ['Task Name', 'Start Date', 'End Date', 'Predecessors','Outline Level'];

const ExcelProcessor = ({fileInputRef, excelTasks, setExcelTasks, projectId}) => {
  const [jsonData, setJsonData] = useState(null);
  const [error, setError] = useState('');
  const [showMappingModal, setShowMappingModal] = useState(false);
  const [uploadedData, setUploadedData] = useState([]);
  const [originalHeaders, setOriginalHeaders] = useState([]);
  const [headerMapping, setHeaderMapping] = useState({});
  const [modalStep, setModalStep] = useState(1);
  const [sheetName, setSheetName] = useState('');
  const [unifiedTable, setUnifiedTable] = useState([])
  const [taskTable, setTaskTable] = useState([])
  const [resourceTable, setResourceTable] = useState([])
  const [assignmentTable, setAssignmentTable] = useState([])
  const [manpowerTable, setManpowerTable] = useState([])
  const [file, setFile] = useState()

  const navigate = useNavigate()

  useEffect(() => {
    if (fileInputRef && fileInputRef.current) {
      fileInputRef.current.addEventListener('change', handleFileUpload);
    }
    return () => {
    if (fileInputRef && fileInputRef.current) {
      fileInputRef.current.removeEventListener('change', handleFileUpload);
      }
    };
  }, [fileInputRef]);

  const convertSheetToObjects = (sheetData) => {
    if (!Array.isArray(sheetData)) {
      console.error("Expected sheetData to be an array but got:", sheetData);
      return [];
    }
    const [headers, ...rows] = sheetData;
    return rows.map(row => {
      let obj = {};
      headers.forEach((header, i) => {
        obj[header] = row[i];
      });
      return obj;
    });
  };;

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    console.log("flie", file)
    setFile(file);
  
    if (!file) return;
  
    const reader = new FileReader();
    reader.onload = (event) => {
      try {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
  
        let allSheetsData = {};
        let sheetHeaders = {};
        let sheets = workbook.SheetNames;
  
        sheets.forEach((sheet) => {
          const worksheet = workbook.Sheets[sheet];
          const json = XLSX.utils.sheet_to_json(worksheet, { header: 1, defval: '' });
  
          if (json.length > 0) {
            sheetHeaders[sheet] = json[0]; // Store headers for mapping later
            allSheetsData[sheet] = json; // Store full sheet data
          }
        });
        console.log("allSheetsData", allSheetsData);
        
        // Store extracted data
        setOriginalHeaders(sheetHeaders);
        setUploadedData(allSheetsData);
  
        // Automatically process first sheet if headers match
        const firstSheetName = sheets[0];
        setSheetName(firstSheetName);
  
        if (arraysEqual(sheetHeaders[firstSheetName], DEFAULT_HEADERS)) {
          processData(allSheetsData[firstSheetName], sheetHeaders[firstSheetName]);
        } else {
          setShowMappingModal(true);
          setModalStep(1);
        }
      } catch (err) {
        setError('Invalid file format. Please upload a valid Excel file.');
      }
    };
    reader.readAsArrayBuffer(file);
  };

  const createUnifiedTable = (uploadedData) => {
    // Convert the task and assignment sheets into arrays of objects
    const taskObjects = convertSheetToObjects(uploadedData['Task_Table']);
    const assignmentObjects = convertSheetToObjects(uploadedData['Assignment_Table']);
    const manpowerObjects = convertSheetToObjects(uploadedData['Resource_Table']);
    console.log("manpowerObjects",manpowerObjects);
  
    // Build a unified table by matching each task with its assignment record
    const unifiedTable = taskObjects.map(task => {

  
      // Find the assignment that corresponds to this task by matching the task name
      const assignment = assignmentObjects.find(a => a['Task Name'] === task['Name']);
  
      return {
        taskOrder: Number(task['ID']),
        task: task['Name'],             // from task_table
        duration: task['Duration'],                                // computed duration
        startDate: task['Start'],           // from task_table
        endDate: task['Finish'],               // from task_table
        predecessors: (task['Predecessors']).split(';'),      // from task_table
        outlineLevel:Number(task['Outline Level']),     // from task_table
        manpower: assignment ? assignment['Resource Name'] : '',       // from assignment_table
        workComplete: assignment ? assignment['% Work Complete'] * 100 : 0,       // from assignment_table
        work: assignment ? assignment['Work'] : ''  ,                         // from assignment_table
        units: assignment ? assignment['Units'] : '',
        projectId
      };
    });

    const manpowerTable = manpowerObjects.map(manpower => {
      return {
        name: manpower['Name'],
        maxUnits: manpower['Max Units'],
        standardRate: manpower['Standard Rate'],
        costPerUse: manpower['Cost Per Use'],
        projectId
      };
    });
    console.log("manpowerTable", manpowerTable);
    setManpowerTable(manpowerTable);
    console.log("unifiedTable",unifiedTable);
    setUnifiedTable(unifiedTable);
    return unifiedTable;
  };

  useEffect(() => {
    createUnifiedTable(uploadedData);
  }, [uploadedData])
  
  useEffect(() => {
    let createActivities = async() => {
      try {
        const activities = await activityService.createActivity(unifiedTable);
      } catch (error) {
        console.error(error.message)
      }
    }
    if (unifiedTable.length > 0) {
      createActivities(); 
      }
  }, [unifiedTable])
  
  useEffect(() => {
    let createResources = async ( ) => {
      try {
        const resources = await manpowerService.createManpower(manpowerTable);
      } catch (error) {
        console.error(error.message)
      }
    }
    if (manpowerTable.length > 0) {
      createResources(); 
      }
  }, [manpowerTable])
  

  // const handleFileUpload = (e) => {
  //   const file = e.target.files[0];
  //   setFile(file)
  //   console.log("file",file);
    
  //   if (!file) return;

  //   const reader = new FileReader();
  //   reader.onload = (event) => {
  //     try {
  //       const data = new Uint8Array(event.target.result);
  //       console.log("data",data);
        
  //       const workbook = XLSX.read(data, { type: 'array' });
  //       console.log("workbook",workbook);
  //       const sheetName = workbook.SheetNames[0];
  //       console.log("sheetName",sheetName);
        
  //       setSheetName(sheetName)
  //       const worksheet = workbook.Sheets[sheetName];
  //       const json = XLSX.utils.sheet_to_json(worksheet, { header: 1, defval: '' });

  //       if (json.length < 1) {
  //         setError('Empty file');
  //         return;
  //       }

  //       const uploadedHeaders = json[0];
  //       setOriginalHeaders(uploadedHeaders);
  //       setUploadedData(json);

  //       // Check if headers match exactly
  //       if (arraysEqual(uploadedHeaders, DEFAULT_HEADERS)) {
  //         processData(json, uploadedHeaders);
  //       } else {
  //         setShowMappingModal(true);
  //         setModalStep(1);
  //       }

  //     } catch (err) {
  //       setError('Invalid file format. Please upload a valid Excel file.');
  //     }
  //   };
  //   reader.readAsArrayBuffer(file);
  // };

  useEffect(() => {
    console.log("jsonData",jsonData);
  }, [jsonData])
  

  const processData = (data, headers) => {
    setJsonData({
      data,
      taskNameIndex: headers.indexOf('Task Name'),
      outlineLevelIndex: headers.indexOf('Outline Level')
    });
  };

  const confirmMapping = async() => {
    // Validate all default headers are mapped
    const missingMappings = DEFAULT_HEADERS.filter(h => !headerMapping[h]);
    if (missingMappings.length > 0) {
      setError(`Please map all required fields: ${missingMappings.join(', ')}`);
      return;
    }

    // Reorganize data based on mapping
    // const reorganizedData = uploadedData?.Assignment_Table.map(row => {
    //   return DEFAULT_HEADERS.map(defaultHeader => {
    //     const sourceHeader = headerMapping[defaultHeader];
    //     const index = originalHeaders.indexOf(sourceHeader);
    //     return index !== -1 ? row[index] : '';
    //   });
    // });
    // processData(reorganizedData, DEFAULT_HEADERS);
    setShowMappingModal(false);

    const buildTaskHierarchy = (tasks) => {
      if (!tasks || tasks.length === 0) return [];
  
      // Sort tasks by taskOrder to maintain order
      const sortedTasks = [...tasks].sort((a, b) => a.taskOrder - b.taskOrder);
      
      const hierarchy = [];
      const parentStack = []; // Stack to track parent tasks at each level
  
      sortedTasks.forEach((task) => {
          task.subtasks = [];
  
          // Find the appropriate parent based on outlineLevel
          while (parentStack.length > 0 && parentStack[parentStack.length - 1].outlineLevel >= task.outlineLevel) {
              parentStack.pop();
          }
  
          const parent = parentStack[parentStack.length - 1];
          if (parent) {
              parent.subtasks.push(task);
          } else {
              hierarchy.push(task);
          }
  
          // Push current task to parent stack
          parentStack.push(task);
      });
  
      return hierarchy;
  };
    // const convertToHierarchy = (data) => {
    //   let root = [];
    //   let stack = [{ level: -1, children: root }];
    //   let taskMap = {};
  
    //   for (let i = 1; i < data.length; i++) {
    //     const row = data[i];
    //     const id = i;
    //     const taskName = row[DEFAULT_HEADERS.indexOf('Task Name')];
    //     const outlineLevel = parseInt(row[DEFAULT_HEADERS.indexOf('Outline Level')], 10);
    //     const startDate = row[DEFAULT_HEADERS.indexOf('Start Date')];
    //     const endDate = row[DEFAULT_HEADERS.indexOf('End Date')];
    //     const predecessors = row[DEFAULT_HEADERS.indexOf('Predecessors')]
    //     .split(',')
    //     .map(p => parseInt(p.trim(), 10))
    //     .filter(p => !isNaN(p)); // Convert to numbers and remove invalid values
    //     const newTask = {
    //       id,
    //       name: taskName,
    //       startDate,
    //       endDate,
    //       predecessors, // Store predecessor IDs
    //       subtasks: [],
    //       outlineLevel
    //     };
        
    //     taskMap[id] = newTask;
    //     // Find the correct parent based on outline level
    //     while (stack.length > 0 && stack[stack.length - 1].level >= outlineLevel) {
    //       stack.pop();
    //     }
  
    //     stack[stack.length - 1].children.push(newTask);
    //     stack.push({ level: outlineLevel, children: newTask.subtasks });
    //   }
  
    //   return root;
    // };
  
    const taskHierarchy = buildTaskHierarchy(unifiedTable);
    const hierarchyData = JSON.stringify(taskHierarchy, null, 2);
    const data = {
      name : "first baseline",
      projectId,
      activities:hierarchyData

    }
    console.log("hierarchy data",hierarchyData);
    const baseline = await baselineService.createBaseline(data);
    navigate(0)
    // const template = await taskTemplateService.createTaskTemplate(file?.name, {groups: data});
    // console.log("template",template);
    
    // setExcelTasks(JSON.stringify(taskHierarchy, null, 2));
    // console.log("Final Task JSON:", JSON.stringify(taskHierarchy, null, 2));
  };

  // Helper functions
  const arraysEqual = (a, b) => {
    if (a.length !== b.length) return false;
    return a.every((val, index) => val === b[index]);
  };
  

  const renderHeaderValidationStep = () => (
    <div className="modal-step">
      <h2>Verify Header Structure</h2>
      <p>Your file headers don't match our format. Please ensure they follow this structure:</p>
      <div className="image-container">
        <img src={headerExample} alt="Header example" className="header-example" />
      </div>
      <button onClick={() => setModalStep(2)}>Continue to Mapping</button>
    </div>
  );

  const renderMappingInterface = () => {
    const headersForSelectedSheet = originalHeaders[sheetName] || [];
    return(
    <div  className="modal-step">
      <h2>Map Your Columns</h2>
      <p>Match your column headers to our required format:</p>
      
      <div className="mapping-table">
        <div className="mapping-row header">
          <div>Your Column</div>
          <div>Our System</div>
        </div>

        {DEFAULT_HEADERS.map((defaultHeader) => (
          <div key={defaultHeader} className="mapping-row">
            <div>
              <select
                value={headerMapping[defaultHeader] || ''}
                onChange={(e) => setHeaderMapping(prev => ({
                  ...prev,
                  [defaultHeader]: e.target.value
                }))}
              >
                <option value="">Select column</option>
                {headersForSelectedSheet.map(header => (
                  <option key={header} value={header}>{header}</option>
                ))}
              </select>
            </div>
            <div className="default-header">
              {defaultHeader}
            </div>
          </div>
        ))}
      </div>

      <button onClick={confirmMapping}>Confirm Mapping</button>
      {error && <div className="error-message">{error}</div>}
    </div>
  )};

  const renderTasks = (data) => {
    if (!data || !data.data) return null;

    return (
      <div className="task-list">
        <h3>Processed Tasks:</h3>
        <ul>
          {data.data.slice(1).map((row, index) => {
            const outlineLevel = row[data.outlineLevelIndex]; // Get the outline level
            const style = {
              paddingLeft: `${outlineLevel * 20}px`, // Indent based on outline level
              fontWeight: outlineLevel < 1 ? 'bold' : 'normal', // Bold for higher levels
              color: outlineLevel === 1 ? 'blue' : outlineLevel === 2 ? 'green' : 'black' // Color coding
            };

            return (
              <li key={index} style={style}>
                {row[data.taskNameIndex]} - Start: {row[1]}, End: {row[2]}
              </li>
            );
          })}
        </ul>
      </div>
    );
};
  return (
    <div className="excel-processor">
      <p>Upload Activity</p>
      <input type="file" accept=".xlsx,.xls,.csv" ref={fileInputRef} onChange={handleFileUpload} style={{ display: 'none' }} />
      {error && <div className="error">{error}</div>}
      <Modal
        isOpen={showMappingModal}
        onRequestClose={() => setShowMappingModal(false)}
        className="mapping-modal"
        overlayClassName="modal-overlay"
      >
        {modalStep === 1 ? renderHeaderValidationStep() : renderMappingInterface()}
      </Modal>

    </div>
  );
};

// CSS Styles
const styles = `
.excel-processor {
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;
}

.mapping-modal {
  padding: 2rem;
  width: 620px;
  position: relative;
  background: white;
  border-radius: 8px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-step {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.mapping-table {
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow: hidden;
  width: 100%;
}

.mapping-row {
  display: flex;
  padding: 1rem;
  border-bottom: 1px solid #eee;
}

.mapping-row.header {
  background: #f8f9fa;
  font-weight: bold;
}

.mapping-row > div {
  flex: 1;
  padding: 0 1rem;
}

select {
  padding: 0.5rem;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.header-example {
  max-width: 100%;
  height: auto;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.error {
  color: #dc3545;
  margin-top: 1rem;
}

.task-list {
  margin-top: 2rem;
  padding: 1rem;
  background: #f8f9fa;
  border-radius: 4px;
}
`;

// Add styles to head
document.head.insertAdjacentHTML('beforeend', `<style>${styles}</style>`);
export default ExcelProcessor;

// export default ExcelProcessor;