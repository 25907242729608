import React, { useEffect, useState } from 'react';
import { Button, Input, List, Modal, Card } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import taskTemplateService from '../../services/taskTemplateService';

const TaskTemplateModal = ({ isModalVisible, handleModalClose, setTemplates, editingTemplateId, onTaskTemplateCreated }) => {
  const [groups, setGroups] = useState([]);
  const [title, setTitle] = useState('');
  const [newGroup, setNewGroup] = useState('');
  const [newGroupId, setNewGroupId] = useState(''); // New state for group ID
  const [newSubGroup, setNewSubGroup] = useState('');
  const [editingGroup, setEditingGroup] = useState(null);
  const [editingSubGroup, setEditingSubGroup] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [groupModalVisible, setGroupModalVisible] = useState(false);

  useEffect(() => {
    if (isModalVisible) {
      if (editingTemplateId) {
        // Fetch the template data if in edit mode
        const fetchTemplateData = async () => {
          const template = await taskTemplateService.getTaskTemplateById(editingTemplateId);
          setGroups(template.groups); // Set groups for editing
          setTitle(template.title); // Set title for editing
        };
        fetchTemplateData();
      } else {
        // Reset fields for creating a new template
        setGroups([]);
        setTitle('');
      }
    }
  }, [isModalVisible, editingTemplateId]);


  const handleAddGroup = () => {
    if (newGroup && newGroupId) { // Check if both fields are filled
      setGroups([...groups, { group: newGroup, id: newGroupId, subGroup: [] }]); // Include ID in group object
      setNewGroup('');
      setNewGroupId(''); // Reset group ID input
      setGroupModalVisible(false);
    }
  };

  const handleAddSubGroup = (groupIndex) => {
    if (newSubGroup) {
      const updatedGroups = [...groups];
      updatedGroups[groupIndex].subGroup.push(newSubGroup);
      setGroups(updatedGroups);
      setNewSubGroup('');
    }
  };

  const handleEditSubGroup = (groupIndex, subGroupIndex) => {
    const subGroup = groups[groupIndex].subGroup[subGroupIndex];
    setNewSubGroup(subGroup);
    setEditingSubGroup({ groupIndex, subGroupIndex });
    setModalVisible(true);
  };

  const handleDeleteGroup = (index) => {
    const updatedGroups = groups.filter((_, i) => i !== index);
    setGroups(updatedGroups);
  };

  const handleDeleteSubGroup = (groupIndex, subGroupIndex) => {
    const updatedGroups = [...groups];
    updatedGroups[groupIndex].subGroup.splice(subGroupIndex, 1);
    setGroups(updatedGroups);
  };

  const handleModalOk = () => {
    if (editingGroup !== null) {
      const updatedGroups = [...groups];
      updatedGroups[editingGroup].group = newGroup;
      setGroups(updatedGroups);
      setEditingGroup(null);
    }
    if (editingSubGroup) {
      const { groupIndex, subGroupIndex } = editingSubGroup;
      const updatedGroups = [...groups];
      updatedGroups[groupIndex].subGroup[subGroupIndex] = newSubGroup;
      setGroups(updatedGroups);
      setEditingSubGroup(null);
    }
    setModalVisible(false);
    setNewGroup('');
    setNewSubGroup('');
  };

  const handleCreateTaskTemplate = async () => {
    try {
      const template = await taskTemplateService.createTaskTemplate(title, groups);
      onTaskTemplateCreated && onTaskTemplateCreated(template);
      const templates = await taskTemplateService.getTaskTemplates();
      setTemplates(templates);
      // Optionally reset state or show a success message
      console.log('Task template created successfully!');
      // Reset state only if creation is successful
      setModalVisible(false);
      setNewGroup('');
      setNewSubGroup('');
      setTitle('');
      setGroups([]);
      handleModalClose();

      // Handle the response if needed
      // For example, you could update the state with the new templates
      // setTaskTemplates(templates);
    } catch (error) {
      console.error('Error creating task template:', error);
    }
  };
  return (
    <Modal
      title="Task Template Groups"
      visible={isModalVisible}
      onCancel={handleModalClose}
      width={"90%"} // Set your desired width
      footer={[
        <Button key="back" onClick={handleModalClose}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={handleCreateTaskTemplate} disabled={!title || groups.length === 0}>
          {editingTemplateId ? 'Update ' : 'Create '}Task Template
        </Button>,
      ]}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
          <label style={{ marginBottom: '10px' }}>Template Title :</label>
          <Input
            placeholder="Please enter a Template title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            style={{ marginBottom: '10px', width: '300px' }}
          />
        </div>
        <Button type="primary" onClick={() => setGroupModalVisible(true)} style={{ marginBottom: '10px' }}>Add Group</Button>
      </div>
      <div className='task-template-form'>
        {groups.map((item, index) => (
          <Card title={<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}><p style={{ fontWeight: 'bold', marginBottom: '2px' }}>{item.group}</p><p style={{ fontSize: '14px', fontWeight: 'normal' }}> ID : {item.id}</p></div>} extra={<Button
            type="link"
            danger
            icon={<DeleteOutlined />}
            onClick={() => handleDeleteGroup(index)}
          ></Button>}>
            <List
              dataSource={item.subGroup}
              renderItem={(subItem, subIndex) => (
                <List.Item
                  style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                >
                  <List.Item.Meta title={subItem} />
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Button onClick={() => handleEditSubGroup(index, subIndex)} style={{ marginBottom: '2px' }}><EditOutlined /></Button>
                    <Button onClick={() => handleDeleteSubGroup(index, subIndex)} style={{ marginBottom: '2px' }}><DeleteOutlined /></Button>
                  </div>
                </List.Item>
              )}
            />
            <Input
              placeholder="New Sub Group"
              value={newSubGroup}
              onChange={(e) => setNewSubGroup(e.target.value)}
              style={{ marginBottom: '10px', width: '100%' }}
            />
            <Button type="primary" onClick={() => handleAddSubGroup(index)}>Add Sub Group</Button>
          </Card>
        ))}
      </div>
      <Modal
        title="Edit Group/SubGroup"
        visible={modalVisible}
        onOk={handleModalOk}
        onCancel={() => setModalVisible(false)}
      >
        <label>Sub Group Name :</label>
        <Input
          placeholder="Sub Group Name"
          value={newSubGroup}
          onChange={(e) => setNewSubGroup(e.target.value)}
        />
      </Modal>
      <Modal
        title="Add Group"
        visible={groupModalVisible}
        onOk={handleAddGroup}
        onCancel={() => setGroupModalVisible(false)}
      >
        <label>Group Name :</label>
        <Input
          placeholder="Group Name"
          value={newGroup}
          onChange={(e) => setNewGroup(e.target.value)}
        />
        <label>Group ID :</label>
        <Input
          placeholder="Group ID" // New input for Group ID
          value={newGroupId}
          onChange={(e) => setNewGroupId(e.target.value)}
        />
      </Modal>
    </Modal>
  );
};

export default TaskTemplateModal;