import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import { Table, DatePicker, InputNumber, Modal, Input, Button } from 'antd';
import dayjs from 'dayjs';
import activityService from '../../services/activityService';
import StaffCostModal from '../Modals/StaffCostModal';
import CostModal from '../Modals/CostModal';

const DailyTable = forwardRef(({ tasks, staffMembers, currency }, ref) => {
  const [taskData, setTaskData] = useState({});
  const [visible, setVisible] = useState(false);
  const [costModalVisible, setCostModalVisible] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [changedTasks, setChangedTasks] = useState({});
  
  const dataSource = tasks.map(task => ({
    ...task,
    key: task.taskOrder,
  }));
 
  useImperativeHandle(ref, () => ({
    handleSaveChanges  // ✅ Exposing only this method
  }));

  useEffect(() => {
    const initialData = tasks.map((task) => ({
      id: task.id,
      startDate: task.startDate,
      endDate: task.endDate,
      workComplete: task.workComplete,
      subcontractorCost: task.subcontractorCost || [],
      staffHours: task.staffHours || [],
      ...staffMembers.reduce((acc, member) => {
        acc[member] = task[member] || 0; // Initialize staff members' hours if available
        return acc;
      }, {}),
    }));
    setTaskData(initialData);
  }, [tasks, staffMembers]);

  const handleInputChange = async (taskId, field, value) => {
    setChangedTasks((prevChanges) => {
      const updatedTasks = {
        ...prevChanges,
        [taskId]: {
          ...prevChanges[taskId],
          [field]: field === 'staffHours'
          ?[...(prevChanges[taskId]?.staffHours || []), ...value]
          : value
        },
      };
      return updatedTasks;
    });
  };
  
  const handleSaveChanges = async (tasks = changedTasks) => {
    try {
      const updatedTasks = Object.entries(tasks).map(([taskId, changes]) => {
        // Create an object to track unique staff names
        const uniqueStaffNames = {};
  
        const uniqueStaffHours = changes.staffHours?.reduce((acc, entry) => {
          // Get the staff name (key in the object)
          const staffName = Object.keys(entry)[0];
          const staffData = entry[staffName];
  
          // Check if the staff name is already in the uniqueStaffNames object
          if (!uniqueStaffNames[staffName]) {
            // If it's not, add it to the accumulator
            acc.push({
              [staffName]: {
                sum: staffData.sum,
                hours: staffData.hours
              }
            });
  
            // Mark the staff name as added
            uniqueStaffNames[staffName] = true;
          } else {
            // If it is, combine the hours (if needed), for example, sum them
            const existingStaff = acc.find(item => Object.keys(item)[0] === staffName);
            if (existingStaff) {
              existingStaff[staffName].hours = staffData.hours
            }
          }
          return acc;
        }, []) || [];
  
        return {
          id: taskId,
          ...changes,
          staffHours: uniqueStaffHours,
          workComplete: changes.workComplete || undefined
        };
      });  
      if (updatedTasks.length > 0) {
        await activityService.updateActivities(updatedTasks);
      } else {
        console.log("✅ No changes detected.");
      }
    } catch (error) {
      console.error('Error updating tasks:', error);
    }
  };
  

  const renderDatePicker = (value, taskId, field) => (
    <DatePicker
      value={value ? dayjs(value, 'YYYY-MM-DD') : null}
      onChange={(date, dateString) => handleInputChange(taskId, field, dateString)}
      format="YYYY-MM-DD"
      style={{ width: '100%' }}
      allowClear={false}
    />
  );

  const renderStaffHours = (record, member) => {
    const staffData = record.staffHours?.find(staff => staff[member]);
    const changedStaffHours = changedTasks[record.id]?.staffHours?.find(staff => staff[member]);
    const costData = record.subcontractorCost;
    const changedCost = changedTasks[record.id]?.subcontractorCost;
    const costMember = member === "Sous-traitant QC Cout" 
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {/* Render the staff hours */}
        <InputNumber
          value={ costMember ? changedCost?.totalCost || costData?.totalCost || 0 : changedStaffHours?.[member]?.sum || staffData?.[member]?.sum || 0}
          style={{ width: '100%' }}
          addonAfter={costMember ? "$" : "h"}
          disabled
        />
        {/* Button to trigger modal */}
        <Button
          type="primary"
          style={{ marginLeft: '10px' }}
          onClick={() => {
            setSelectedTask(record); 
            setSelectedStaff(member);
            costMember ?  setCostModalVisible(true) : setVisible(true)
            
          }}
        >
          +
        </Button>
      </div>
    );
  };

  const columns = [
    { title: 'Task Name', dataIndex: 'task', key: 'task', width: '300px' , fixed: 'left'},
    {
      title: 'Actual Start',
      dataIndex: 'startDate',
      key: 'startDate',
      render: (_, record) => renderDatePicker(changedTasks[record.id]?.startDate || record.startDate, record.id, 'startDate'),
      width: '200px',
    },
    {
      title: 'Actual End',
      dataIndex: 'endDate',
      key: 'endDate',
      render: (_, record) => renderDatePicker(changedTasks[record.id]?.endDate || record.endDate, record.id, 'endDate'),
      width: '200px',
    },
    {
      title: 'Progress',
      dataIndex: 'workComplete',
      key: 'workComplete',
      render: (_, record) => (
        <InputNumber
          value={changedTasks[record.id]?.workComplete || record.workComplete || 0}
          onChange={(num) => handleInputChange(record.id, 'workComplete', num)}
          style={{ width: '100%' }}
          addonAfter="%"
        />
      ),
      width: '150px',
    },
    ...staffMembers.map(member => ({
      title: `${member}`,
      dataIndex: member,
      key: member,
      render: (_, record) => renderStaffHours(record, member),
      width: '300px',
    })),
  ];

  return (
    <>
      <Table dataSource={dataSource} columns={columns} pagination={false} scroll={{ x: 'max-content' }} />
      {/* Merchandise Modal */}
      <StaffCostModal
        visible={visible}
        setVisible={setVisible}
        selectedTask={selectedTask}
        setTaskData={setTaskData}
        selectedStaff={selectedStaff}
        staffHours={ changedTasks[selectedTask?.id]?.staffHours || selectedTask?.staffHours || []}
        handleInputChange={handleInputChange}
      />
      <CostModal
        visible={costModalVisible}
        setVisible={setCostModalVisible}
        selectedTask={selectedTask}
        selectedStaff={selectedStaff}
        subcontractorCost={ changedTasks[selectedTask?.id]?.subcontractorCost || selectedTask?.subcontractorCost || []}
        handleInputChange={handleInputChange}
        currency={currency}
      />
    </>
  );
});

export default DailyTable;
