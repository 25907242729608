import { Button, Input, Modal } from 'antd';
import React, { useState, useEffect } from 'react';

function CostModal({ visible, setVisible, selectedTask, selectedStaff, subcontractorCost, currency, handleInputChange }) {
    const [newMerchandise, setNewMerchandise] = useState({ name: '', cost: '' });
    const [merchandiseList, setMerchandiseList] = useState([]); // Default as empty array
    const [totalCost, setTotalCost] = useState(0); // Separate state for total cost

    useEffect(() => {
        // Ensure subcontractorCost is always an array
        setMerchandiseList(subcontractorCost?.merchandiseList ? subcontractorCost?.merchandiseList : []);
        setTotalCost(calculateTotalCost(subcontractorCost || [] )); 
    }, [subcontractorCost, selectedTask]);

    // Function to calculate total cost
    const calculateTotalCost = (list) => {
        // Ensure that list is an array before calling reduce
        if (Array.isArray(list)) {
            return list.reduce((sum, item) => sum + item.cost, 0);
        }
        return subcontractorCost?.totalCost || 0; // Return 0 if list is not an array
    };
    const handleAddMerchandise = () => {
        const cost = parseFloat(newMerchandise.cost);

        if (newMerchandise.name && !isNaN(cost)) {
            // Create new merchandise item
            const newMerchandiseItem = { name: newMerchandise.name, cost };

            // Update the merchandise list
            const updatedMerchandise = [...merchandiseList, newMerchandiseItem];

            // Update total cost
            const newTotalCost = calculateTotalCost(updatedMerchandise);
            setTotalCost(newTotalCost);

            // Set updated merchandise list
            setMerchandiseList(updatedMerchandise);

            // Clear input fields after adding
            setNewMerchandise({ name: '', cost: '' });
        }
    };

    const handleDeleteMerchandise = (index) => {
        // Remove the item from the list
        const updatedMerchandise = merchandiseList.filter((_, i) => i !== index);

        // Update total cost
        const newTotalCost = calculateTotalCost(updatedMerchandise);
        setTotalCost(newTotalCost);

        // Set updated merchandise list after deleting an item
        setMerchandiseList(updatedMerchandise);
    };
    return (
        <Modal
            title={`${selectedStaff}`}
            visible={visible}
            onCancel={() => setVisible(false)}
            onOk={() => {
                setVisible(false);
                // Send merchandise list and totalCost only if totalCost is greater than 0
                const payload = {
                    merchandiseList,  // The list of merchandise items
                };

                if (totalCost > 0) {
                    payload.totalCost = totalCost;  // Only send totalCost if it’s greater than 0
                }

                handleInputChange(selectedTask?.id, 'subcontractorCost', payload);  // Send data to parent or backend
            }}
        >
            <h2 style={{ marginBottom: '10px', fontSize: '20px' }}> {selectedTask?.phase}</h2>
            <h3 style={{ marginBottom: '10px', fontSize: '16px' }}>Task Name : {selectedTask?.task}</h3>

            <>
                <Input
                    placeholder="Merchandise Name"
                    value={newMerchandise.name}
                    onChange={(e) => setNewMerchandise({ ...newMerchandise, name: e.target.value })}
                    style={{ marginBottom: '10px' }}
                />
                <Input
                    type="number"
                    placeholder="Cost"
                    value={newMerchandise.cost}
                    onChange={(e) => setNewMerchandise({ ...newMerchandise, cost: e.target.value })}
                    addonAfter={currency}
                />
                <Button type="primary" onClick={handleAddMerchandise} style={{ marginTop: '10px' }}>
                    Add
                </Button>

                <div style={{ marginTop: '20px' }}>
                    <h4>Added Merchandise and Costs:</h4>
                    <ul>
                        { merchandiseList?.map((item, index) => (
                            <li key={index}>
                                {item.name}: {item.cost} {currency}
                                <Button
                                    type="link"
                                    onClick={() => handleDeleteMerchandise(index)}
                                    style={{ color: 'red', marginLeft: '10px' }}
                                >
                                    Delete
                                </Button>
                            </li>
                        ))}
                    </ul>
                    <h4>Total Cost: {totalCost} {currency}</h4>
                </div>
            </>
        </Modal>
    );
}

export default CostModal;
