import axios from 'axios';


const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000/api';


const manpowerService = {
    createManpower: async (manpowerTable) => {
        const response = await axios.post(`${API_URL}/manpower`, {manpowerData:manpowerTable},{
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        });
        return response.data;
    },
    getManpowerbyProjectId: async (projectId) => {
        const response = await axios.get(`${API_URL}/manpower/project/${projectId}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        });
        return response.data.resources;
    }
}

export default manpowerService;
