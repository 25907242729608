import React, { useEffect, useRef, useState } from 'react';

import GanttChart from '../Gantt/GanttChart';
import { Button, Input, Spin } from 'antd';
import { ViewMode } from 'gantt-task-react';
import { useSearchParams } from 'react-router-dom';
import taskTemplateService from '../../services/taskTemplateService';
import activityService from '../../services/activityService';
const LIMIT = 10;
const ProjectStatus = ({ data }) => {
  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
  // Define tasks and subtasks
  const [timeFrame, setTimeFrame] = useState(ViewMode.Day); // State to hold the selected time frame
  const [searchParams] = useSearchParams(); // Use useSearchParams to get the query parameters
  const projectId = searchParams.get('projectId'); // Get projectId from query parameters
  const ganttRef1 = useRef(null);
  const ganttRef2 = useRef(null);
  const [tasks1, setTasks1] = useState([]);
  const [tasks2, setTasks2] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [offset, setOffset] = useState(0);
  const scrollBarRef = useRef(null);
  const [scrollWidth, setScrollWidth] = useState(1000)

  const getTaskTemplate = async () => {
    try {
      const activities = await activityService.getActivitiesByProject(projectId,offset,LIMIT);

      // const template = await taskTemplateService.getTaskTemplateByProjectId(projectId,offset);
      const newTasks1 = activities.activities.filter(task => task.outlineLevel > 1);
      const newTasks2 = activities.activities.filter(task => task.outlineLevel > 1);
      if (newTasks1.length === 0 && newTasks2.length === 0) {
        setHasMore(false); // No more tasks to load
      }      
      setTasks1(prevTasks => [...prevTasks, ...newTasks1]);
      setTasks2(prevTasks => [...prevTasks, ...newTasks2]);
      setIsLoading(false);

    } catch (error) {
      console.error('Error fetching assigned task template:', error);
      setIsLoading(false);    }
  }

  useEffect(() => {
    getTaskTemplate()
  }, [offset])

  // Handle scroll synchronization
const handleScroll = (event) => {
  if (!ganttRef1.current || !ganttRef2.current) return;

  const { scrollTop, scrollHeight, clientHeight } = ganttRef1.current;

  // Ensure smooth synchronization between the two charts
  ganttRef2.current.scrollTop = scrollTop;

  // Check if we reached the bottom
  if (scrollTop + clientHeight >= scrollHeight - 10) {
    if (hasMore && !isLoading) {
      setIsLoading(true); // Start loading more tasks
      setOffset((prevOffset) => prevOffset + LIMIT); // Increase offset for pagination
    }
  }
};

  const syncScroll = (event) => {
    const scrollLeft = event.target.scrollLeft;
    if (ganttRef1.current && ganttRef2.current) {
      ganttRef1.current.querySelector("._CZjuD").scrollLeft = scrollLeft;
      ganttRef2.current.querySelector("._CZjuD").scrollLeft = scrollLeft;
    }
  };
  const updateScrollWidth = () => {
    if (ganttRef1.current) {
      const timeline1 = ganttRef1.current.querySelector("._CZjuD");
      if (timeline1) {
        setScrollWidth(timeline1.scrollWidth);
      }
    }
  };
  useEffect(() => {
    const attachScrollListeners = () => {
      if (!ganttRef1.current || !ganttRef2.current) return;

      const timeline1 = ganttRef1.current.querySelector("._CZjuD");
      const timeline2 = ganttRef2.current.querySelector("._CZjuD");

      if (timeline1 && timeline2) {
        // Sync scroll with custom scrollbar
        scrollBarRef.current.addEventListener("scroll", syncScroll);
        updateScrollWidth();
      }
    };

    setTimeout(attachScrollListeners, 1000);

    return () => {
      if (scrollBarRef.current) {
        scrollBarRef.current.removeEventListener("scroll", syncScroll);
      }
    };
  }, [handleScroll]);

  const filteredTasks1 = tasks1.filter(task =>
    task.task.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredTasks2 = tasks2.filter(task =>
    task.task.toLowerCase().includes(searchTerm.toLowerCase())
  );
  
  return (
    <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", gap: 25 }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Input
          placeholder="Search tasks..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ marginBottom: 8, width: 300 }}
        />
        <div style={{ display: "flex", gap: 10 }}>
        <Button type="primary" onClick={() => setTimeFrame(ViewMode.Day)}>Day</Button>
        <Button type="primary" onClick={() => setTimeFrame(ViewMode.Week)}>Week</Button>
        <Button type="primary" onClick={() => setTimeFrame(ViewMode.Month)}>Month</Button>
        <Button type="primary" onClick={() => setTimeFrame(ViewMode.Year)}>Year</Button>
        </div>
      </div>
      <div style={{ overflowX: "auto", width: "100%", height: 20 }} ref={scrollBarRef}>
        <div style={{  width: `${scrollWidth}px`, height: "20px" }}></div>
      </div>
      { (tasks1.length === 0 && tasks2.length === 0) ? (
        <Spin size="large" />
      ) : (
        <>
          <GanttChart tasks={filteredTasks1} ganttRef={ganttRef1} handleScroll={handleScroll} timeFrame={timeFrame} />
          <GanttChart tasks={filteredTasks2} ganttRef={ganttRef2} handleScroll={handleScroll} timeFrame={timeFrame} />
        </>
      )}
    </div>
  );
};

export default ProjectStatus;