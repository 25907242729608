// Jira-inspired theme configuration for Ant Design
export const jiraTheme = {
  token: {
    colorPrimary: '#0052CC',
    colorSuccess: '#36B37E',
    colorWarning: '#FFAB00',
    colorError: '#FF5630',
    colorInfo: '#0065FF',
    borderRadius: 3,
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial',
  },
  components: {
    Button: {
      borderRadius: 3,
      paddingContentHorizontal: 16,
    },
    Card: {
      borderRadius: 3,
    },
    Input: {
      borderRadius: 3,
    },
    Select: {
      borderRadius: 3,
    },
    Table: {
      borderRadius: 3,
    },
  },
};