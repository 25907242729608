import axios from 'axios';


const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000/api/v1';

const activityService = {
  getProjectActivities: async (projectId) => {
    const response = await axios.get(`${API_URL}/activities/project/${projectId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
    return response.data;
  },
  getActivitiesWithPhases: async (projectId) => {
    try {
        const response = await axios.get(`${API_URL}/activities/activities-with-phases/${projectId}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        });
        return response.data;
    } catch (error) {
        console.error('Failed to fetch activities with phases:', error);
        throw error;
    }
},
  createActivity: async (unifiedTable) => {
    console.log("activityData", unifiedTable);
    const response = await axios.post(`${API_URL}/activities`, { activityData: unifiedTable }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
    return response.data;
  },

  createSubtask: async (subtaskData) => {
    const response = await axios.post(`${API_URL}/activities/createSubtask`, subtaskData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
    return response.data;
  },

  updateActivity: async (id, activityData) => {
    const response = await axios.put(`${API_URL}/activities/${id}`, activityData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
    return response.data;
  },

  deleteActivity: async (id) => {
    const response = await axios.delete(`${API_URL}/activities/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
    return response.data;
  },

  updateProgress: async (id, progressData) => {
    const response = await axios.put(
      `${API_URL}/activities/${id}/progress`,
      progressData,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      }
    );
    return response.data;
  },

  getActivityMetrics: async (id) => {
    const response = await axios.get(`${API_URL}/activities/${id}/metrics`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
    return response.data;
  },

  // Additional methods for specific activity-related operations
  exportActivityReport: async (id, format = 'pdf') => {
    const response = await axios.get(
      `${API_URL}/activities/${id}/export`,
      {
        params: { format },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        responseType: 'blob'
      }
    );
    return response.data;
  },

  updateResourceAssignments: async (id, assignmentData) => {
    const response = await axios.put(
      `${API_URL}/activities/${id}/assignments`,
      assignmentData,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      }
    );
    return response.data;
  },

  getAllActivitiesByProject: async (projectId) => {
    const response = await axios.get(`${API_URL}/activities/project/${projectId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
    return response.data;
  },

  getActivitiesByProject: async (projectId, offset, limit) => {
    const response = await axios.get(`${API_URL}/activities/project/${projectId}/activities?offset=${offset}&limit=${limit}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
    return response.data;
  },

  getActivitiesByProjectAndTask: async (projectId, offset, limit, taskName) => {
    const response = await axios.get(`${API_URL}/activities/project/${projectId}/activities/task?offset=${offset}&limit=${limit}&taskName=${taskName}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
    return response.data;
  },

  updateActivities: async (tasks) => {
    const response = await axios.put(`${API_URL}/activities/updateActivities`, { tasks }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
    return response.data;
  },
  getActivityById: async (id) => {
    const response = await axios.get(`${API_URL}/activities/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
    return response.data;
  }

};

export default activityService;