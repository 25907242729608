import axios from 'axios';
import { API_BASE_URL } from '../utils/constants';

const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Add auth token to requests
api.interceptors.request.use(config => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

const shipyardService = {
  // Get all shipyards with optional filtering
  getAllShipyards: async (params = {}) => {
    try {
      const response = await api.get('/shipyards', { params });
      return response.data;
    } catch (error) {
      throw error.response?.data || error;
    }
  },

  // Get single shipyard by ID
  getShipyardById: async (id) => {
    try {
      const response = await api.get(`/shipyards/${id}`);
      return response.data;
    } catch (error) {
      throw error.response?.data || error;
    }
  },

  // Create new shipyard
  createShipyard: async (data) => {
    try {
      const formData = new FormData();
      // Handle file upload if logo is included
      if (data.logo) {
        formData.append('logo', data.logo);
        delete data.logo;
      }
      // Add other shipyard data
      Object.keys(data).forEach(key => {
        if (key === 'staffMembers') {
          formData.append(key, JSON.stringify(data[key]));
        } else if (key === 'Logistics' && Array.isArray(data[key])) {
          formData.append(key, JSON.stringify(data[key]));
        } else {
          formData.append(key, data[key]);
        }
      });
      const response = await api.post('/shipyards', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      return response.data;
    } catch (error) {
      throw error.response?.data || error;
    }
  },

  // Update existing shipyard
  updateShipyard: async (id, data) => {
    try {
      const formData = new FormData();
      // Handle file upload if logo is included
      if (data.logo) {
        formData.append('logo', data.logo);
        delete data.logo;
      }
      // Add other shipyard data
      Object.keys(data).forEach(key => {
        if (key === 'staffMembers') {
          formData.append(key, JSON.stringify(data[key]));
        } else if (key === 'Logistics' && Array.isArray(data[key])) {
          formData.append(key, JSON.stringify(data[key]));
        } else {
          formData.append(key, data[key]);
        }
      });

      const response = await api.put(`/shipyards/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      return response.data;
    } catch (error) {
      throw error.response?.data || error;
    }
  },

  // Delete shipyard
  deleteShipyard: async (id) => {
    try {
      const response = await api.delete(`/shipyards/${id}`);
      return response.data;
    } catch (error) {
      throw error.response?.data || error;
    }
  },

  // Update shipyard status
  updateStatus: async (id, status) => {
    try {
      const response = await api.put(`/shipyards/${id}/status`, { status });
      return response.data;
    } catch (error) {
      throw error.response?.data || error;
    }
  },

  // Get shipyard metrics
  getShipyardMetrics: async (id) => {
    try {
      const response = await api.get(`/shipyards/${id}/metrics`);
      return response.data;
    } catch (error) {
      throw error.response?.data || error;
    }
  },

  // Get shipyard capacity
  getCapacityMetrics: async (id) => {
    try {
      const response = await api.get(`/shipyards/${id}/capacity`);
      return response.data;
    } catch (error) {
      throw error.response?.data || error;
    }
  },

  // Get resource utilization
  getResourceUtilization: async (id) => {
    try {
      const response = await api.get(`/shipyards/${id}/resource-utilization`);
      return response.data;
    } catch (error) {
      throw error.response?.data || error;
    }
  },

  // Update resource capacity
  updateResourceCapacity: async (id, resourceUpdates) => {
    try {
      const response = await api.put(`/shipyards/${id}/resource-capacity`, resourceUpdates);
      return response.data;
    } catch (error) {
      throw error.response?.data || error;
    }
  }
};

export default shipyardService;