import axios from 'axios';


const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000/api';


const activityEstimatesService = {
    bulkInsert: async (data) => {
        console.log("activityEstimatesService", data);
    
        const modifiedData = data.map(item => ({
            ...item,
            values: JSON.stringify(item.values)
        }));
    
        try {
            const projectId = modifiedData[0]?.projectId;
            if (!projectId) {
                throw new Error("Missing projectId in activity estimates data.");
            }
    
            const checkResponse = await axios.get(`${API_URL}/activity-estimates?projectId=${projectId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
    
            if (checkResponse.data.length > 0) {
                console.warn(`Activity estimates already exist for projectId: ${projectId}. Aborting insert.`);
                return { message: "Activity estimates already exist for this project." };
            }
    
            const response = await axios.post(`${API_URL}/activity-estimates`, { data: modifiedData }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                }
            });
    
            return response.data;
    
        } catch (error) {
            console.error('Failed to insert activity estimates:', error);
            throw error;
        }
    },
    getEstimates: async (projectId) => {
        try {
            const response = await axios.get(`${API_URL}/activity-estimates/${projectId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            return response.data;
        } catch (error) {
            console.error('Failed to fetch activity estimates:', error);
            throw error;
        }
    }
};


export default activityEstimatesService;