import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Card, Form, Input, message as antdMessage } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import authService from '../../services/authService';

const ResetPassword = () => {
    const [searchParams] = useSearchParams();
    const token = searchParams.get("token"); // Get token from query params
    const email = searchParams.get("email");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const handleSubmit = async ({ password }) => {
        setLoading(true);
        try {
            const response = await authService.resetPassword({ email, token, password });
            if (response) {
                antdMessage.success(response.message);
                navigate('/login');
            }
        } catch (error) {
            const errorMsg = error.response?.data?.message
            antdMessage.error(errorMsg);
        }
        setLoading(false);
    };

    return (
        <div style={{
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: '#f0f2f5',
            padding: '20px'
        }}>
            <Card style={{ width: 400, textAlign: 'center' }}>
                <h1 style={{ marginBottom: 24 }}>Reset Password</h1>
                <Form onFinish={handleSubmit} layout="vertical">
                    <Form.Item
                        name="password"
                        label="New Password"
                        rules={[{ required: true, message: 'Please enter your new password' }]}
                    >
                        <Input.Password
                            prefix={<LockOutlined />}
                            placeholder="Enter new password"
                            size="large"
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" block size="large" loading={loading}>
                            Reset Password
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </div>
    );
};

export default ResetPassword;
