import React, { createContext, useContext, useState } from "react";
import activityService from "../services/activityService";

// Create the context
const ActivityContext = createContext(null);

export const ActivityProvider = ({ children }) => {
    // State to hold the activities data
    const [newActivities, setNewActivities] = useState([]);

    // Function to get activities by project and task
   
    const updateActivities = async (tasks) => {
        try {
            const response = await activityService.updateActivities(tasks);
            const updatedTasks = response?.updatedTasks || [];
            console.log("updated",updatedTasks)
            // Update the activities, checking for duplicates by ID
            setNewActivities((prevActivities) => {
                const updatedActivities = [...prevActivities];

                updatedTasks.forEach((newTask) => {
                    // Check if the task already exists by ID
                    const existingTaskIndex = updatedActivities.findIndex(
                        (task) => task.task === newTask.task
                    );
          
                    if (existingTaskIndex !== -1) {
                        // If the task exists, replace it with the new task
                        updatedActivities[existingTaskIndex] = newTask;
                    } else {
                        // If the task doesn't exist, add it
                        updatedActivities.push(newTask);
                    }
                });

                return updatedActivities;
            });
            return response;
        } catch (error) {
            console.error("Failed to update activities", error);
        }
    };

    return (
        <ActivityContext.Provider value={{ newActivities, updateActivities }}>
            {children}
        </ActivityContext.Provider>
    );
};

// Custom hook to use the ActivityContext
export const useActivityContext = () => useContext(ActivityContext);
