import React, { useEffect } from 'react';
import { Form, Input, Button, Card, message, Checkbox } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import logo from '../../assets/appLogo.PNG';

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { login, user, loading } = useAuth();
  const [form] = Form.useForm();

  useEffect(() => {
    const savedUser = JSON.parse(localStorage.getItem('user'));
    if (savedUser) {
      // Assuming the saved user info is valid, auto-login
      login(savedUser);
      navigate(location.state?.from?.pathname || '/projects', { replace: true });
    }
  }, [login, location, navigate]);

  // Redirect if user is already logged in
  useEffect(() => {
    if (!loading && user) {
      const from = location.state?.from?.pathname || '/projects';
      navigate(from, { replace: true });
    }
  }, [user, loading, navigate, location]);

  const handleSubmit = async (values) => {
    try {
      const success = await login(values);
      if (success) {
        message.success('Login successful');
        // Get the redirect path from location state or default to dashboard
        if (values.rememberMe) {
          // Store user info in localStorage
          localStorage.setItem('user', JSON.stringify(values));
        }
        const from = location.state?.from?.pathname || '/projects';
        navigate(from, { replace: true });
      }
    } catch (error) {
      message.error('Login failed');
    }
  };

  // Show loading state while checking authentication
  if (loading) {
    return <div>Loading...</div>;
  }

  // Don't render login form if user is already authenticated
  if (user) {
    return null;
  }

  return (
    <div style={{
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: '#f0f2f5'
    }}>
      <Card style={{ width: 400 }}>
        <div style={{ marginBottom: 24,gap: 12, display: 'flex', alignItems: 'center'  }}>
          <img src={logo} alt="app logo" height={50} width={50} />
          <h1>ShipEnsign</h1>
        </div>
        <Form
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
        >
          <Form.Item
            name="email"
            rules={[
              { required: true, message: 'Please enter your email' },
              { type: 'email', message: 'Please enter a valid email' }
            ]}
          >
            <Input
              prefix={<UserOutlined />}
              placeholder="Email"
              size="large"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please enter your password' }]}
          >
            <Input.Password
              prefix={<LockOutlined />}
              placeholder="Password"
              size="large"
            />
          </Form.Item>
          
          <Form.Item name="rememberMe" valuePropName="checked" noStyle>
            <Checkbox>Remember me</Checkbox>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" block size="large">
              Log in
            </Button>
          </Form.Item>
        </Form>
        <Link to="/forgot-password">Forgot your password?</Link>
      </Card>
    </div>
  );
};

export default Login;