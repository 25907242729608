import React, { useEffect, useState, useRef, useMemo } from 'react';
import MainLayout from '../../components/Layout/MainLayout';
import { useSearchParams } from 'react-router-dom';
import activityService from '../../services/activityService';
import { Button, Input } from 'antd';
import DailyTable from '../../components/DailyInput/DailyTable';
import manpowerService from '../../services/manpowerService';
import { useActivityContext } from '../../context/ActivityContext';

const LIMIT = 15;

function DailyInput() {
    const [tasks, setTasks] = useState([]);
    const [staffMembers, setStaffMembers] = useState([]);
    const [searchParams] = useSearchParams();
    const projectId = searchParams.get('projectId');
    const currency = searchParams.get('currency');
    const [offset, setOffset] = useState(0);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const hasFetched = useRef(false);
    const childRef = useRef(null);
    const [filterText, setFilterText] = useState('');
    // Function to reset state for fresh fetch
    const resetData = () => {
        setTasks([]);
        setOffset(0);
        setHasMore(true);
    };

    // Unified fetch function
    const fetchTasks = async (isFilterMode = false) => {
        if (!projectId) return;

        setLoading(true);
        try {
            const activities = await activityService.getActivitiesByProjectAndTask(
                projectId,
                isFilterMode ? 0 : offset, // Start from 0 in filter mode
                LIMIT,
                filterText
            );

            const newTasks = activities.activities.filter(
                (task) => task.outlineLevel !== 0 && task.outlineLevel !== 1
            );

            if (isFilterMode) {
                setTasks(newTasks);
                setHasMore(newTasks.length >= LIMIT); // Prevent looping when no results
            } else {
                setTasks((prevTasks) => [...prevTasks, ...newTasks]);
                setHasMore(newTasks.length >= LIMIT); // Only continue if there are more tasks
                setOffset((prevOffset) => prevOffset + LIMIT);
            }
        } catch (error) {
            console.error("Error fetching tasks:", error);
            setHasMore(false); // Stop fetching if an error occurs
        }
        setLoading(false);
    };

    const fetchStaffMembers = async () => {
        if (!projectId) return;
        try {
            const resources = await manpowerService.getManpowerbyProjectId(projectId);

            const staffNames = resources
            .filter(resource => resource.name && resource.name.trim() !== "")
            .map(resource => resource.name);
            setStaffMembers(staffNames);
        } catch (error) {
            console.error("Error fetching staff members:", error);
        }
    };

    useEffect(() => {
        if (!projectId) return;

        if (filterText) {
            resetData();
            fetchTasks(true); // Filter mode
        } else if (!hasFetched.current) {
            resetData();
            fetchTasks(); // Initial fetch
            fetchStaffMembers();
            hasFetched.current = true;
        }
    }, [filterText, projectId]);

    // Add scroll event listener for pagination
    useEffect(() => {
        if (!projectId) return;

        const element = document.getElementById('scrollableDiv');
        if (!element) return;

        const handleScroll = () => {
            const bottomReached = element.scrollTop + element.clientHeight >= element.scrollHeight - 10;
            if (bottomReached) {
                console.log("Bottom reached, fetching more tasks. Current offset:", offset);
                fetchTasks();
            }
        };

        element.addEventListener('scroll', handleScroll);
        return () => element.removeEventListener('scroll', handleScroll);
    }, [loading, hasMore, projectId, filterText, offset]);

    const handleClick = () => {
        if (childRef.current) {
            childRef.current.handleSaveChanges(); // ✅ Correct method call
        }
    };
    
    return (
        <MainLayout>
            <div style={{ marginBottom: '20px' , display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Input
                    placeholder="Filter activities..."
                    value={filterText}
                    onChange={(e) => setFilterText(e.target.value)}
                    style={{ width: '300px' }}
                />
                <div style={{  textAlign: 'right' }}>
                    <Button type="primary" onClick={handleClick}>
                        Save Changes
                    </Button>
                </div>
            </div>
            <div id="scrollableDiv" style={{ height: '80vh', overflow: 'auto', width: '100%', position: 'relative' }}>
                <DailyTable tasks={tasks} staffMembers={staffMembers} currency={currency} ref={childRef} />
                {loading && <p>Loading more tasks...</p>}
            </div>
        </MainLayout>
    );
}

export default DailyInput;
