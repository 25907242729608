import React, { Children } from "react";
import { Gantt } from "gantt-task-react";
import "gantt-task-react/dist/index.css"; // Import the default CSS

const GanttChart = ({ tasks, ganttRef, handleScroll, timeFrame }) => {
  const convertTaskDates = (tasks) => {
    if (!tasks || tasks.length === 0) return [];
    return tasks.map(task => ({
      ...task,
      name: task?.task,
      start: new Date(task?.startDate),
      end: new Date(task?.endDate),
      progress: task?.units,
      id: task.taskOrder?.toString(),
      dependencies: task?.predecessors || [],
      progress: task?.workComplete,
    }));
  };

  return (
    <div ref={ganttRef} onScroll={handleScroll} style={{ width: "100%", height: "350px", overflowX: "auto", }}>
      <Gantt
        ref={ganttRef}
        tasks={convertTaskDates(tasks)}
        viewMode={timeFrame} // Set the view mode
        onDateChange={(task) => console.log("Task date changed:", task)}
        onProgressChange={(task) => console.log("Task progress changed:", task)}
        onDelete={(task) => console.log("Task deleted:", task)}
        columnWidth={200}
      />
    </div>
  );
};

export default GanttChart;