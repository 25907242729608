import React, { useState, useEffect } from 'react';
import { Table, Card, Button, Space, Modal, Image, message } from 'antd';
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import MainLayout from '../../components/Layout/MainLayout';
import ShipyardForm from './ShipyardForm';
import shipyardService from '../../services/shipyardService';
import ResourcesTable from './ResourcesTable';

const Shipyards = () => {
  const [shipyards, setShipyards] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedShipyard, setSelectedShipyard] = useState(null);
  const [resourcesModalVisible, setResourcesModalVisible] = useState(false);
  const [selectedShipyardId, setSelectedShipyardId] = useState(null);

  useEffect(() => {
    fetchShipyards();
  }, []);

  const fetchShipyards = async () => {
    setLoading(true);
    try {
      const data = await shipyardService.getAllShipyards();
      console.log("data",data)
      setShipyards(data);
    } catch (error) {
      message.error('Failed to fetch shipyards');
    } finally {
      setLoading(false);
    }
  };

  const handleCreate = () => {
    setSelectedShipyard(null);
    setModalVisible(true);
  };

  const handleEdit = (shipyard) => {
    setSelectedShipyard(shipyard);
    setModalVisible(true);
  };

  const handleDelete = async (id) => {
    try {
      await shipyardService.deleteShipyard(id);
      message.success('Shipyard deleted successfully');
      fetchShipyards();
    } catch (error) {
      message.error('Failed to delete shipyard');
    }
  };

  const handleViewResources = (shipyardId) => {
    setSelectedShipyardId(shipyardId);
    setResourcesModalVisible(true);
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Logo',
      dataIndex: 'logo',
      key: 'logo',
      render: (logo) => logo && (
        <Image
          src={`${process.env.REACT_APP_API_URL}/uploads/${logo}`}
          alt="Shipyard Logo"
          style={{ width: 50, height: 50 }}
        />
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <Button
            type="link"
            onClick={() => handleViewResources(record.id)}
          >
            View Resources
          </Button>
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={() => handleEdit(record)}
          >
            Edit
          </Button>
          <Button
            type="link"
            danger
            icon={<DeleteOutlined />}
            onClick={() => handleDelete(record.id)}
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <MainLayout>
      <Card
        title="Shipyards"
        extra={
          <Button type="primary" icon={<PlusOutlined />} onClick={handleCreate}>
            New Shipyard
          </Button>
        }
      >
        <Table
          columns={columns}
          dataSource={shipyards}
          rowKey="id"
          loading={loading}
        />
      </Card>

      <Modal
        title={selectedShipyard ? 'Edit Shipyard' : 'Create Shipyard'}
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
        width={800}
      >
        <ShipyardForm
          shipyard={selectedShipyard}
          onSuccess={() => {
            setModalVisible(false);
            fetchShipyards();
          }}
          onCancel={() => setModalVisible(false)}
        />
      </Modal>

      <Modal
        title="Shipyard Resources"
        open={resourcesModalVisible}
        onCancel={() => setResourcesModalVisible(false)}
        width={1000}
        footer={null}
      >
        <ResourcesTable shipyardId={selectedShipyardId} />
      </Modal>
    </MainLayout>
  );
};

export default Shipyards;