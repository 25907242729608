import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form, Input, InputNumber, Select, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import resourceService from '../../services/resourceService';

const { Option } = Select;

const ResourcesTable = ({ shipyardId }) => {
  const [resources, setResources] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (shipyardId) {
      fetchResources();
    }
  }, [shipyardId]);

  const fetchResources = async () => {
    setLoading(true);
    try {
      const data = await resourceService.getResourcesByShipyard(shipyardId);
      setResources(data);
    } catch (error) {
      message.error('Failed to fetch resources');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (values) => {
    try {
      await resourceService.createResource({
        ...values,
        shipyardId
      });
      message.success('Resource added successfully');
      setModalVisible(false);
      fetchResources();
      form.resetFields();
    } catch (error) {
      message.error('Failed to add resource');
    }
  };

  const handleUpdateResource = async (id, values) => {
    try {
      await resourceService.updateResource(id, values);
      message.success('Resource updated successfully');
      fetchResources();
    } catch (error) {
      message.error('Failed to update resource');
    }
  };

  const columns = [
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      render: (category) => category.replace('_', ' ').toUpperCase(),
      filters: [
        { text: 'Mechanic', value: 'mechanic' },
        { text: 'Welder', value: 'welder' },
        { text: 'Electrician', value: 'electrician' },
        { text: 'Painter', value: 'painter' },
        { text: 'Quality Control', value: 'quality_control' },
        { text: 'General Worker', value: 'general_worker' },
      ],
      onFilter: (value, record) => record.category === value,
    },
    {
      title: 'Base Rate ($/hour)',
      dataIndex: 'baseRate',
      key: 'baseRate',
      render: (rate) => `$${rate.toFixed(2)}`,
      sorter: (a, b) => a.baseRate - b.baseRate,
    },
    {
      title: 'Available',
      dataIndex: 'availableCount',
      key: 'availableCount',
      sorter: (a, b) => a.availableCount - b.availableCount,
      render: (count, record) => (
        <InputNumber
          min={0}
          value={count}
          onChange={(value) => handleUpdateResource(record.id, { availableCount: value })}
        />
      ),
    },
    {
      title: 'Assigned',
      dataIndex: 'assignedCount',
      key: 'assignedCount',
      sorter: (a, b) => a.assignedCount - b.assignedCount,
    },
    {
      title: 'Utilization',
      key: 'utilization',
      render: (_, record) => {
        const utilization = record.assignedCount / record.availableCount * 100;
        let color = 'green';
        if (utilization > 90) color = 'red';
        else if (utilization > 75) color = 'orange';
        return <span style={{ color }}>{utilization.toFixed(1)}%</span>;
      },
      sorter: (a, b) => (a.assignedCount / a.availableCount) - (b.assignedCount / b.availableCount),
    },
  ];

  return (
    <div>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={() => setModalVisible(true)}
        style={{ marginBottom: 16 }}
      >
        Add Resource
      </Button>

      <Table
        columns={columns}
        dataSource={resources}
        rowKey="id"
        loading={loading}
        pagination={{ pageSize: 10 }}
      />

      <Modal
        title="Add Resource"
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
        >
          <Form.Item
            name="category"
            label="Category"
            rules={[{ required: true, message: 'Please select a category' }]}
          >
            <Select>
              <Option value="mechanic">Mechanic</Option>
              <Option value="welder">Welder</Option>
              <Option value="electrician">Electrician</Option>
              <Option value="painter">Painter</Option>
              <Option value="quality_control">Quality Control</Option>
              <Option value="general_worker">General Worker</Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="baseRate"
            label="Base Rate ($/hour)"
            rules={[{ required: true, message: 'Please enter base rate' }]}
          >
            <InputNumber
              min={0}
              style={{ width: '100%' }}
              formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
            />
          </Form.Item>

          <Form.Item
            name="availableCount"
            label="Available Count"
            rules={[{ required: true, message: 'Please enter available count' }]}
          >
            <InputNumber min={0} style={{ width: '100%' }} />
          </Form.Item>

          <Form.Item
            name="skills"
            label="Skills"
          >
            <Select mode="tags" style={{ width: '100%' }} placeholder="Add skills">
              <Option value="welding">Welding</Option>
              <Option value="fabrication">Fabrication</Option>
              <Option value="inspection">Inspection</Option>
              <Option value="maintenance">Maintenance</Option>
            </Select>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              Add Resource
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default ResourcesTable;