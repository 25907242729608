import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000/api';

const projectService = {
  getAllProjects: async () => {
    const response = await axios.get(`${API_URL}/projects`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
    return response.data;
  },

  getProjectById: async (id) => {
    const response = await axios.get(`${API_URL}/projects/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
    return response.data;
  },

  createProject: async (projectData) => {
    const response = await axios.post(`${API_URL}/projects`, projectData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
    return response.data;
  },

  updateProject: async (id, projectData) => {
    const response = await axios.put(`${API_URL}/projects/${id}`, projectData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
    return response.data;
  },

  deleteProject: async (id) => {
    const response = await axios.delete(`${API_URL}/projects/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
    return response.data;
  },

  // Additional project-related services
  getProjectActivities: async (projectId) => {
    const response = await axios.get(`${API_URL}/projects/${projectId}/activities`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
    return response.data;
  },

  getProjectMetrics: async (projectId) => {
    const response = await axios.get(`${API_URL}/projects/${projectId}/metrics`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
    return response.data;
  }
};

export default projectService;