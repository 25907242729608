import React from 'react';
import { Row, Col } from 'antd';
import ProjectStatus from '../../components/Dashboard/ProjectStatus';
import MainLayout from '../../components/Layout/MainLayout';

const Dashboard = () => {
  const projectStatusData = [
    { name: 'Completed', value: 30 },
    { name: 'In Progress', value: 45 },
    { name: 'Pending', value: 15 },
    { name: 'Delayed', value: 10 },
  ];

  return (
    <MainLayout>
          <ProjectStatus data={projectStatusData} />
    </MainLayout>
  );
};

export default Dashboard;