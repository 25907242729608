import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000/api';

const assignedTaskTemplateService = {
    async cloneAssignedTaskTemplate(templateId) {
        try {
            const response = await axios.post(`${API_URL}/assigned-task-templates/clone/${templateId}`, {}, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            // Check for both 200 and 201 status codes for successful cloning
            if (response.status !== 200 && response.status !== 201) {
                throw new Error(`Failed to clone assigned task template: ${response.statusText}`);
            }

            const newTemplate = response.data; // Corrected response handling
            return newTemplate;
        } catch (error) {
            console.error('Error cloning assigned task template:', error);
            throw new Error(`Error cloning assigned task template: ${error.message}`);
        }
    },
    async updateAssignedTaskTemplate(id, projectId) {
        try {

            const response = await axios.put(`${API_URL}/assigned-task-templates/${id}`, { projectId }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            // Check for both 200 and 201 status codes for successful update
            if (response.status !== 200 && response.status !== 201) {
                throw new Error(`Failed to update assigned task template: ${response.statusText}`);
            }

            const updatedTemplate = response.data; // Corrected response handling
            return updatedTemplate;
        } catch (error) {
            console.error('Error updating assigned task template:', error);
            throw new Error(`Error updating assigned task template: ${error.message}`);
        }
    },
    async getAssignedTaskTemplateById(templateId) {
        try {
            const response = await axios.get(`${API_URL}/assigned-task-templates/${templateId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            // Check for successful response
            if (response.status !== 200) {
                throw new Error(`Failed to fetch assigned task template: ${response.statusText}`);
            }
            return response.data;
        } catch (error) {
            console.error('Error fetching assigned task template:', error);
            throw new Error(`Error fetching assigned task template: ${error.message}`);
        }
    }
};

export default assignedTaskTemplateService;