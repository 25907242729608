// API Constants
export const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000/api';

// Authentication
export const AUTH_TOKEN_KEY = 'auth_token';
export const REFRESH_TOKEN_KEY = 'refresh_token';

// Pagination
export const DEFAULT_PAGE_SIZE = 10;
export const PAGE_SIZE_OPTIONS = ['10', '20', '50', '100'];

// Resource Categories
export const RESOURCE_CATEGORIES = [
  { value: 'mechanic', label: 'Mechanic' },
  { value: 'welder', label: 'Welder' },
  { value: 'electrician', label: 'Electrician' },
  { value: 'painter', label: 'Painter' },
  { value: 'quality_control', label: 'Quality Control' },
  { value: 'general_worker', label: 'General Worker' }
];

// Project Statuses
export const PROJECT_STATUSES = [
  { value: 'pending', label: 'Pending', color: '#faad14' },
  { value: 'in_progress', label: 'In Progress', color: '#1890ff' },
  { value: 'completed', label: 'Completed', color: '#52c41a' },
  { value: 'delayed', label: 'Delayed', color: '#ff4d4f' }
];

// Activity Statuses
export const ACTIVITY_STATUSES = [
  { value: 'pending', label: 'Pending', color: '#faad14' },
  { value: 'in_progress', label: 'In Progress', color: '#1890ff' },
  { value: 'completed', label: 'Completed', color: '#52c41a' }
];

// File Upload
export const MAX_FILE_SIZE = 5; // in MB
export const ALLOWED_FILE_TYPES = {
  images: ['image/jpeg', 'image/png', 'image/gif'],
  documents: ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
  spreadsheets: ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
};

// Chart Colors
export const CHART_COLORS = {
  primary: '#1890ff',
  success: '#52c41a',
  warning: '#faad14',
  error: '#ff4d4f',
  series: ['#1890ff', '#52c41a', '#faad14', '#ff4d4f', '#722ed1', '#13c2c2']
};

// Time Intervals
export const TIME_INTERVALS = {
  REFRESH_INTERVAL: 300000, // 5 minutes
  SESSION_TIMEOUT: 3600000, // 1 hour
  TOKEN_REFRESH_THRESHOLD: 300 // 5 minutes before token expiry
};

// Error Messages
export const ERROR_MESSAGES = {
  NETWORK_ERROR: 'Network error. Please check your connection.',
  SERVER_ERROR: 'Server error. Please try again later.',
  UNAUTHORIZED: 'Please log in to continue.',
  FORBIDDEN: 'You do not have permission to perform this action.',
  NOT_FOUND: 'The requested resource was not found.',
  VALIDATION_ERROR: 'Please check your input and try again.'
};

// Success Messages
export const SUCCESS_MESSAGES = {
  CREATED: 'Successfully created.',
  UPDATED: 'Successfully updated.',
  DELETED: 'Successfully deleted.',
  SAVED: 'Changes saved successfully.'
};

// Form Layouts
export const FORM_LAYOUT = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 }
};

// Responsive Breakpoints
export const BREAKPOINTS = {
  xs: 480,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1600
};