import React, { useEffect, useState } from 'react'
import activityService from '../../services/activityService';
import { useParams } from 'react-router-dom';
import activityEstimatesService from '../../services/activityEstimatesService';
import { Table } from 'antd';
const extractSegment = (activityName) => {
    if (!activityName || typeof activityName !== "string") return null;
    const match = activityName.match(/\d{2}\.\d{2}/);
    return match ? match[0] : null;
};

const CostEstimates = () => {
    const [activities, setActivities] = useState([]);
    const [estimates, setEstimates] = useState([]);
    const [pagination, setPagination] = useState({ pageSize: 5 });
    const {id} = useParams();
    console.log("projectId",id);
    
    const fetchEstimates = async (projectId) => {
        try {
            const data = await activityEstimatesService.getEstimates(projectId);
            setEstimates(data.estimates);
        } catch (error) {
            console.error("Error fetching activity estimates:", error);
        }
    };

    /*log effect*/
    // useEffect(() => {
    //     console.log(estimates.map(el=>extractSegment(el.activity)))
    // }, [estimates]);

    useEffect(() => {
        fetchEstimates(id);
    }, [id]);
    useEffect(() => {
        const fetchActivities = async () => {
            try {
                const data = await activityService.getActivitiesWithPhases(id);
                const formattedData = data.map(activity => ({
                    ...activity,
                    phases: activity.phases ? activity.phases.split(', ') : [] // Convert to array
                }));
                setActivities(formattedData);
            } catch (error) {
                console.error("Error fetching activities:", error);
            }
        };

        if (id) fetchActivities();
    }, [id]);

    // Merge activities with estimates
    const mergedActivities = activities?.map(activity => {
        const activityCode = extractSegment(activity?.task);
        if (activityCode == null) {
            console.log("null activityCode",activity.task);
        }
        
        const matchingEstimate = estimates.find(est => extractSegment(est.activity) === activityCode);
        console.log("matchingEstimate",{activity,activityCode,matchingEstimate});
        
        return {
            ...activity,
            estimateValue: matchingEstimate ? JSON.parse(JSON.parse(`${matchingEstimate.values}`)) : "N/A"
        };

    });
    console.log("mergedActivities",mergedActivities);
    const columns = [
        {
            title: 'Activity',
            dataIndex: 'task',
            key: 'task',
        },
        {
            title: 'Phases',
            dataIndex: 'phases',
            key: 'phases',
            render: (phases) => (
                <ul>
                    {Array.isArray(phases) && phases.length > 0 ? (
                        phases.map((phase, index) => <li key={index}>{phase}</li>)
                    ) : (
                        <span>—</span> // Display a dash if no phases exist
                    )}
                </ul>
            )
        },
        {
            title: 'Estimated Value',
            dataIndex: 'estimateValue',
            key: 'estimateValue',
            render: (estimateValue) => (
                estimateValue && typeof estimateValue === 'object' ? (
                    <ul style={{ paddingLeft: '16px' }}>
                        {Object.entries(estimateValue).map(([key, value]) => (
                            <li key={key}><strong>{key}:</strong> {value}</li>
                        ))}
                    </ul>
                ) : (
                    <span>—</span> // Display a dash if no estimates exist
                )
            )
        }
    ];
    

  return (

<div style={{ maxWidth: '1000px', margin: '20px auto', padding: '20px', background: '#fff', borderRadius: '8px', boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)' }}>
<h2 style={{ marginBottom: '16px' }}>Activity Estimates</h2>
<Table
    columns={columns} 
    dataSource={mergedActivities} 
    pagination={{
        current: pagination.current,
        pageSize: pagination.pageSize,
        showSizeChanger: true, // Enables page size dropdown
        onChange: (page, pageSize) => setPagination({ current: page, pageSize }) // Updates pagination state
    }} 
    bordered
/>
</div>
)
}

export default CostEstimates