// TaskTemplates.js
import React, { useEffect, useState } from 'react';
import MainLayout from '../../components/Layout/MainLayout';
import { Button, Card, Input, Space, Table } from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import taskTemplateService from '../../services/taskTemplateService';
import TaskTemplateModal from '../../components/TaskTemplate/TaskTemplateModal';
// import { fetchTaskTemplates, createTaskTemplate, deleteTaskTemplate } from '../services/taskTemplateService';

const TaskTemplates = () => {
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingTemplateId, setEditingTemplateId] = useState(null); // ID of the template being edited
  const [searchQuery, setSearchQuery] = useState('');

  const filteredTemplates = templates.filter(template =>
    template.title.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  const handleDeleteTaskTemplate = async (id) => {
    try {
      await taskTemplateService.deleteTaskTemplate(id);
      const updatedTemplates = await taskTemplateService.getTaskTemplates();
      setTemplates(updatedTemplates);
      console.log('Task template deleted successfully.');
    } catch (error) {
      console.error('Error deleting task template:', error);
    }
  };

  const handleEditTaskTemplate = (id) => {
    setEditingTemplateId(id);
    setIsModalVisible(true);
  };

  const columns = [
    {
      title: 'Reference ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={() => handleEditTaskTemplate(record.id)}
          >
            Edit
          </Button>
          <Button
            type="link"
            danger
            icon={<DeleteOutlined />}
            onClick={() => handleDeleteTaskTemplate(record.id)}
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    const loadTemplates = async () => {
      const data = await taskTemplateService.getTaskTemplates();
      setTemplates(data);
    };
    loadTemplates();
  }, []);

  return (
    <MainLayout>
      <Card
        title="Task Templates"
        extra={
          <Button type="primary" icon={<PlusOutlined />} onClick={() => { setIsModalVisible(true); setEditingTemplateId(null) }}>
            New Template
          </Button>
        }
      >
        <Input
          placeholder="Search by title"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          style={{ marginBottom: '20px' }}
        />
        <Table
          columns={columns}
          dataSource={filteredTemplates}
          rowKey="id"
          loading={loading}
        />
      </Card>
      <TaskTemplateModal
        isModalVisible={isModalVisible}
        handleModalClose={handleModalClose}
        setTemplates={setTemplates}
        editingTemplateId={editingTemplateId}
      />
    </MainLayout>
  );
};

export default TaskTemplates;