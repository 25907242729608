
import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Collapse, Form, Input, message, Modal } from 'antd';
import ActivityForm from '../../components/Activities/ActivityForm';
import { useParams } from 'react-router-dom';
import activityService from '../../services/activityService';
import { DeleteOutlined } from '@ant-design/icons';
import projectService from '../../services/projectService';
import assignedTaskTemplateService from '../../services/assignedTaskTemplateService';
import taskTemplateService from '../../services/taskTemplateService';
import ExcelProcessor from '../Test';
import * as XLSX from 'xlsx';
import activityEstimatesService from '../../services/activityEstimatesService';


const  {Panel} = Collapse

function ProjectActivities() {
    const { id } = useParams();
    const [taskHierarchy, setTaskHierarchy] = useState([]);
    const [activityGroups, setActivityGroups] = useState([]);
    const [projectActivities, setProjectActivities] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [selectedActivity, setSelectedActivity] = useState(null);
    const [selectedTask, setSelectedTask] = useState(null);
    const [editedTask, setEditedTask] = useState(null);
    const [isSubtaskModalVisible, setIsSubtaskModalVisible] = useState(false);
    const [newSubtask, setNewSubtask] = useState({ task: '', outlineLevel: 0, taskOrder: 0, predecessors: [] });
    const [data , setData] = useState([])
    const [table, setTable] = useState([])

    // const handleFileUpload = (event) => {
    //     const file = event.target.files[0];
    
        
    //     if (file) {
    //       const reader = new FileReader();
          
    //       reader.onload = (e) => {
    //         const binaryStr = e.target.result;
    //         const workbook = XLSX.read(binaryStr, { type: "binary" });
    
    //         // Assuming the first sheet is the one we need
    //         const sheetName = workbook.SheetNames[0];
    //         const sheet = workbook.Sheets[sheetName];
    
    //         // Convert sheet to JSON format (array of arrays)
    //         let rawData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
    
    //         // Ignore the first 5 rows
    //         rawData = rawData.slice(5);
    
    //         setData(rawData);
    //         console.log("Processed data for second file:", rawData);
    //       };
    
    //       reader.readAsBinaryString(file);
    //     }
    //   };

    const handleFileUpload = (file) => {
        if (!file) return;
    
        // Ask for confirmation before processing the file
        const confirmUpload = window.confirm(`Are you sure you want to upload "${file.name}"?`);
        if (!confirmUpload) return;
    
        const reader = new FileReader();
        reader.onload = async (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: "array" });
            let allSheetsData = [];
    
            workbook.SheetNames.forEach((sheetName, sheetIndex) => {
                const worksheet = workbook.Sheets[sheetName];
                const sheetData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
    
                if (sheetData.length < 9) return; // Ensure enough rows exist
    
                // Extract headers for the first sheet (indexes 4-9 and 25-32)
                let headers = sheetIndex === 0
                    ? [...(sheetData[6]?.slice(4, 10) || []), ...(sheetData[6]?.slice(25, 33) || [])]
                    : sheetData[6]?.slice(5, 11) || []; // For all other sheets (indexes 5-10)
    
                let lastActivityName = null; // Track last known activity for subtasks
    
                // Process rows starting from row 9 (index 8)
                for (let i = 8; i < sheetData.length; i++) {
                    let row = sheetData[i];
    
                    // Find the index where the activity name starts (it may shift)
                    let activityIndex = row[2] ? 2 : 3; // Adjust based on empty cells
                    let activityName = row[activityIndex] ? row[activityIndex] : lastActivityName;
    
                    if (!activityName) continue; // Skip completely empty rows
    
                    lastActivityName = activityName; // Update last known activity
    
                    // Extract values based on sheet index
                    let values = sheetIndex === 0
                        ? [...(row.slice(4, 10) || []), ...(row.slice(25, 33) || [])]
                        : row.slice(5, 11) || []; // Adjusted indexes for second and other sheets
    
                    // Ensure headers and values align correctly
                    const mappedValues = headers
                        .map((h, idx) => (h ? [h, values[idx] ?? null] : null)) // Ensure valid key-value pairs
                        .filter(entry => entry !== null); // Remove invalid entries
    
                    // Ensure mappedValues is a valid object before converting to JSON
                    if (mappedValues.length > 0) {
                        allSheetsData.push({
                            activity: activityName,
                            values: JSON.stringify(Object.fromEntries(mappedValues)),
                            type: sheetName,
                            projectId: id
                        });
                    }
                }
            });
            console.log("allSheetsData",allSheetsData);
            
            // Send the processed data to the backend
            await activityEstimatesService.bulkInsert(allSheetsData);
        };
        reader.readAsArrayBuffer(file);
    };
    
     


/** insert data into database */

    //   useEffect(() => {
    //     if (table.length > 0) {
    //       const saveEstimates = async () => {
    //         try {
    //           await activityEstimatesService.bulkInsert(table);
    //           message.success("Estimates saved successfully");
    //         } catch (error) {
    //           message.error("Failed to save estimates");
    //         }
    //       };
    //       saveEstimates()
    //     }
    //   }, [table])
      

    const excelProcessorRef = useRef(null);
    const handleExcelProcessorClick = (event) => {
        event.stopPropagation(); 
        if (excelProcessorRef.current) {
            if (excelProcessorRef.current.opened) return; 
            excelProcessorRef.current.opened = true;
            excelProcessorRef.current.click();
        }
    };

    const fetchGroupsData = async () => {
        try {
            const project = await projectService.getProjectById(id);
            const uploadedTasks = await taskTemplateService.getTaskTemplateById(project.taskTemplateId)
            if (uploadedTasks && uploadedTasks.groups && uploadedTasks.groups.groups) {
                // Parse the JSON string
                const parsedGroups = JSON.parse(uploadedTasks.groups.groups);
                setTaskHierarchy(parsedGroups); // Now it's an array
                // setActivityGroups(parsedGroups);
            } else {
                setTaskHierarchy([]);
            }
            if (!uploadedTasks){
                const TaskTemplate = await assignedTaskTemplateService.getAssignedTaskTemplateById(project.assignedTaskTemplateId);
                setActivityGroups(TaskTemplate?.groups || []);
            }
        } catch (error) {
            console.error('Error fetching activity groups:', error);
        }
    };
    const fetchActivityData = async () => {
        try {
            const data = await activityService.getProjectActivities(id);
            console.log("activity data",data)
            setProjectActivities(data.activities);
        } catch (error) {
            console.error('Failed to fetch activities');
        }
    };

    const buildTaskHierarchy = (tasks) => {
        if (!tasks || tasks.length === 0) return [];
    
        // Sort tasks by taskOrder to maintain order
        const sortedTasks = [...tasks].sort((a, b) => a.taskOrder - b.taskOrder);
        
        const hierarchy = [];
        const parentStack = []; // Stack to track parent tasks at each level
    
        sortedTasks.forEach((task) => {
            task.subtasks = [];
    
            // Find the appropriate parent based on outlineLevel
            while (parentStack.length > 0 && parentStack[parentStack.length - 1].outlineLevel >= task.outlineLevel) {
                parentStack.pop();
            }
    
            const parent = parentStack[parentStack.length - 1];
            if (parent) {
                parent.subtasks.push(task);
            } else {
                hierarchy.push(task);
            }
    
            // Push current task to parent stack
            parentStack.push(task);
        });
    
        return hierarchy;
    };


    const handleTaskClick = (task) => {
        setSelectedTask(task);
        setIsModalVisible(true);
    };
    useEffect(() => {
        fetchGroupsData();
        fetchActivityData();
    }, []);


    useEffect(() => {
        console.log("taskHierarchy",taskHierarchy);
    }, [taskHierarchy])
    

    const refreshActivities = async () => {
        await fetchActivityData(); // Re-fetch the activities
    };
    const handleCheckboxClick = (groupName, subgroup) => {
        const selectedActivity = { groupName, subgroup };
        setSelectedGroup(selectedActivity);
        setIsModalVisible(true); // Open the modal
    };
    const handleViewDetails = (task) => {
        setSelectedTask(task);
        setEditedTask({ ...task });
        setIsModalVisible(true);
    };

    const handleModalClose = () => {
        setIsModalVisible(false); // Close the modal
        setSelectedGroup(null); // Reset selected activity
        setSelectedActivity(null);
        setSelectedTask(null);
    };

    const handleInputChange = (e, field) => {
        setEditedTask(prev => ({ ...prev, [field]: e.target.value }));
    };



    const handleConfirmEdit = async () => {
        try {
            await activityService.updateActivity(editedTask.id, editedTask);
            message.success('Task updated successfully');
            setProjectActivities(prev => prev.map(task => task.id === editedTask.id ? editedTask : task));
            handleModalClose();
        } catch (error) {
            console.error('Failed to update task:', error);
            message.error('Failed to update task');
        }
    };
    
    const handleCreateSubtask = (task) => {
        setSelectedTask(task);
        const newTaskOrder = task.taskOrder + 1;
        setProjectActivities(prevTasks => prevTasks.map(t => 
            t.taskOrder >= newTaskOrder ? { ...t, taskOrder: t.taskOrder + 1 } : t
        ));
        setNewSubtask({
            task: '',
            outlineLevel: task.outlineLevel + 1,
            taskOrder: newTaskOrder,
            parentTaskId: task.id,
            predecessors: [],
            work: '',
            units: '',
            workComplete: 0,
            manpower: '',
            duration: '',
            startDate: '',
            endDate: '',
        });
        setIsSubtaskModalVisible(true);
    };

    const handleConfirmSubtask = async () => {
        try {
            await activityService.createSubtask({ ...newSubtask, projectId: id });
            message.success('Subtask created successfully');
            fetchActivityData();
            setIsSubtaskModalVisible(false);
        } catch (error) {
            console.error('Failed to create subtask:', error);
            message.error('Failed to create subtask');
        }
    };;

    const handleDeleteActivity = async (activity) => {
        try {
            await activityService.deleteActivity(activity.id); // Assuming activity has an id property
            setProjectActivities(prevActivities => prevActivities.filter(item => item.id !== activity.id)); // Update state to remove deleted activity
            message.success('Activity deleted successfully');
        } catch (error) {
            console.error('Failed to delete activity:', error);
            message.error('Failed to delete activity');
        }
    };

    const renderTaskTree = (tasks, level = 0) => {
        return tasks.map((task) => (
            <Panel 
                header={task.task} 
                key={task.id}
                style={{
                    width: '100%',
                    margin: '20px auto',
                    marginLeft: `${level * 24}px`, // Indent based on hierarchy level
                    borderLeft: level > 0 ? '2px solid #1890ff' : 'none', // Optional visual cue
                }}
            >
                <span>
                <h4>Task start - end</h4>
                {Date(task.startDate)} - {Date(task.endDate)}
                </span>
                <span>
                <h4>Task duration</h4>
                {task.duration}
                </span>
                <span>
                <h4>Task manpower</h4>
                {task.manpower ? task.manpower : 'N/A'}
                </span>
                <span>
                <h4>Task units</h4>
                {task.units ? task.units : 'N/A'}
                </span>
                <span>
                <h4>Task work</h4>
                {task.work ? task.work : 'N/A'}
                </span>
                <span>
                <h4>Task % work complete</h4>
                {task.workComplete ? task.workComplete : 'N/A'}
                <div style={{ marginTop: '10px'}}>
                    <Button type='primary' size='small' onClick={() => handleViewDetails(task)}>View Details</Button>
                    <Button type='primary' size='small' style={{ marginLeft: '10px' }} onClick={() => handleCreateSubtask(task)}>Create Subtask</Button>
                </div>
                </span>
                {task.subtasks?.length > 0 && (
                    <Collapse ghost bordered={false}>
                        {renderTaskTree(task.subtasks, level + 1)}
                    </Collapse>
                )}
            </Panel>
        ));
    };

    return (
        <div className="project-activities">
            <div>
                {
                    projectActivities.length === 0 && (
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '10px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '95vh', // Full height to center vertically
                            textAlign: 'center' // Center text
                        }}>
                            <h2>Project has no current activities</h2>
                            <div style={{ display: 'flex', gap: '10px', justifyContent: 'center', alignItems: 'center' }}>
                                <Button style={{
                                    padding: '5px 5px',
                                    borderRadius: '5px'
                                }} type='primary'
                                onClick={handleExcelProcessorClick}
                                > <ExcelProcessor fileInputRef={excelProcessorRef} projectId={id} /> </Button>
                                {/* <Button style={{
                                    padding: '10px 20px',
                                    borderRadius: '5px'
                                }} type='primary'>Create Activity</Button> */}
                            </div>
                        </div>
                    )
                }
                {   
                
                    projectActivities.length > 0 && (
                        <div style={{ display: 'inline-block', position: 'relative' }} className='excel-processor'>
                            <Collapse style={{width: '100%'}} ghost accordion>
                                {renderTaskTree(buildTaskHierarchy(projectActivities))}
                            </Collapse>
                            <Button style={{ position: 'absolute', bottom: '10px', right: '10px' }} onClick={() => document.getElementById('fileInput').click()} type='primary' >
                            <p>Upload complementary data</p>
                            <input id='fileInput' style={{ display: 'none' }} type="file" accept=".xlsx, .xls" onChange={(e) => handleFileUpload(e.target.files[0])} />    
                            </Button>
                        </div>
                    )
                }
                <Modal
                title='Task Details'
                visible={isModalVisible}
                onCancel={handleModalClose}
                footer={
                    <Button type='primary' onClick={handleConfirmEdit}>Confirm</Button>
                }
            >
                {editedTask && (
                    <Form layout='vertical'>
                        <Form.Item label='Task Name'>
                            <Input value={editedTask.task} onChange={(e) => handleInputChange(e, 'task')} />
                        </Form.Item>
                        <Form.Item label='Start Date'>
                            <Input type='date' value={editedTask.startDate} onChange={(e) => handleInputChange(e, 'startDate')} />
                        </Form.Item>
                        <Form.Item label='End Date'>
                            <Input type='date' value={editedTask.endDate} onChange={(e) => handleInputChange(e, 'endDate')} />
                        </Form.Item>
                        <Form.Item label='Duration'>
                            <Input value={editedTask.duration} onChange={(e) => handleInputChange(e, 'duration')} />
                        </Form.Item>
                        <Form.Item label='Manpower'>
                            <Input value={editedTask.manpower || 'N/A'} onChange={(e) => handleInputChange(e, 'manpower')} />
                        </Form.Item>
                    </Form>
                )}
            </Modal>
            <Modal
                title='Create Subtask'
                visible={isSubtaskModalVisible}
                onCancel={() => setIsSubtaskModalVisible(false)}
                footer={<Button type='primary' onClick={handleConfirmSubtask}>Confirm</Button>}
            >
                <Form layout='vertical'>
                    <Form.Item label='Subtask Name'>
                        <Input value={newSubtask.task} onChange={(e) => setNewSubtask(prev => ({ ...prev, task: e.target.value }))} />
                    </Form.Item>
                    <Form.Item label='Start Date'>
                        <Input type='date' value={newSubtask.startDate} onChange={(e) => setNewSubtask(prev => ({ ...prev, startDate: e.target.value }))} />
                    </Form.Item>
                    <Form.Item label='End Date'>
                        <Input type='date' value={newSubtask.endDate} onChange={(e) => setNewSubtask(prev => ({ ...prev, endDate: e.target.value }))} />
                    </Form.Item>
                    <Form.Item label='Duration'>
                        <Input value={newSubtask.duration} onChange={(e) => setNewSubtask(prev => ({ ...prev, duration: e.target.value }))} />
                    </Form.Item>
                    <Form.Item label='Manpower'>
                        <Input value={newSubtask.manpower} onChange={(e) => setNewSubtask(prev => ({ ...prev, manpower: e.target.value }))} />
                    </Form.Item>
                    <Form.Item label='Units'>
                        <Input value={newSubtask.units} onChange={(e) => setNewSubtask(prev => ({ ...prev, units: e.target.value }))} />
                    </Form.Item>
                    <Form.Item label='Work'>
                        <Input value={newSubtask.work} onChange={(e) => setNewSubtask(prev => ({ ...prev, work: e.target.value }))} />
                    </Form.Item>
                    <Form.Item label='Work Complete'>
                        <Input value={newSubtask.workComplete} onChange={(e) => setNewSubtask(prev => ({ ...prev, workComplete: e.target.value }))} />
                    </Form.Item>
                </Form>
            </Modal>
            </div>
            
    {/* {data.length > 0 && (
        <pre>{JSON.stringify(data, null, 2)}</pre>
      )} */}
        </div>
    );
}

export default ProjectActivities;
