import { Button, DatePicker, Input, Modal } from 'antd';
import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { DeleteOutlined } from '@ant-design/icons';

function StaffCostModal({ visible, setVisible, selectedTask, selectedStaff, staffHours, handleInputChange }) {
    const [newSubcontractorHours, setNewSubcontractorHours] = useState({ date: '', hours: '' });
    const selectedStaffHours = staffHours.find(staff => Object.keys(staff)[0] === selectedStaff);
    const [staffList, setStaffList] = useState([]);
    const [allStaff, setAllStaff] = useState([]);

    useEffect(() => {
        if (selectedStaffHours?.hasOwnProperty(selectedStaff)) {
            setStaffList([selectedStaffHours]);
        } else {
            setStaffList([]);
        }
    }, [selectedStaffHours, selectedStaff]);

    useEffect(() => {
        setAllStaff(staffHours);
    }, [staffHours]);

    const handleAddSubcontractorHours = () => {
        const hours = parseFloat(newSubcontractorHours.hours);

        if (newSubcontractorHours.date && !isNaN(hours)) {
            setStaffList(prevList => {
                const updatedList = [...prevList];

                const existingStaffIndex = updatedList.findIndex(
                    staff => Object.keys(staff)[0] === selectedStaff
                );

                if (existingStaffIndex !== -1) {
                    const updatedHours = [...updatedList[existingStaffIndex][selectedStaff]?.hours];

                    const existingDateIndex = updatedHours.findIndex(
                        entry => entry.date === newSubcontractorHours.date
                    );

                    if (existingDateIndex !== -1) {
                        // Update existing date entry
                        updatedHours[existingDateIndex].hours += hours;
                    } else {
                        // Add new date entry
                        updatedHours.push({ date: newSubcontractorHours.date, hours });
                    }

                    const newSum = updatedHours.reduce((total, item) => total + parseFloat(item.hours), 0);

                    updatedList[existingStaffIndex] = {
                        [selectedStaff]: {
                            sum: newSum.toString(),
                            hours: updatedHours
                        }
                    };
                } else {
                    updatedList.push({
                        [selectedStaff]: {
                            sum: hours.toString(),
                            hours: [{ date: newSubcontractorHours.date, hours }]
                        }
                    });
                }

                return updatedList;
            });

            setNewSubcontractorHours({ name: '', date: '', hours: '' });
        }
    };


    const handleDeleteSubcontractorHours = (dateToDelete) => {
        setStaffList(prevList =>
            prevList
                .map(staff => {
                    if (staff[selectedStaff]) {
                        const updatedHours = staff[selectedStaff].hours.filter(
                            (entry) => entry.date !== dateToDelete
                        );

                        if (updatedHours.length === 0) {
                            return null; // Mark for removal
                        }

                        return {
                            [selectedStaff]: {
                                sum: updatedHours.reduce((total, item) => total + parseFloat(item.hours), 0).toString(),
                                hours: updatedHours
                            }
                        };
                    }
                    return staff;
                })
                .filter(Boolean) // Remove null entries
        );
    };

    const updateAllStaff = (staffList, selectedStaff) => {
        if (staffList.length === 0) {
            return allStaff.map(staff => {
                // Check if the staff name matches selectedStaff
                const staffName = Object.keys(staff)[0];

                if (staffName === selectedStaff) {
                    // Replace the selectedStaff with the new object
                    return {
                        [staffName]: {
                            sum: "0",
                            hours: []
                        }
                    };
                }

                // Return the staff unchanged if no match
                return staff;
            });
        }

        return staffList.reduce((updatedAllStaff, newStaff) => {
            const staffKey = Object.keys(newStaff)[0];
            const newHours = newStaff[staffKey].hours;

            // Exclude empty staff entries
            if (!newHours.length) return updatedAllStaff;

            const existingStaffIndex = updatedAllStaff.findIndex(
                (staff) => Object.keys(staff)[0] === staffKey
            );

            if (existingStaffIndex !== -1) {
                const existingHours = updatedAllStaff[existingStaffIndex][staffKey].hours;

                // Filter out deleted entries properly
                const combinedHours = existingHours
                    .filter((entry) => newHours.some((newHour) => newHour.date === entry.date))
                    .concat(newHours.filter((newHour) =>
                        !existingHours.some((entry) => entry.date === newHour.date)
                    ));

                const newSum = combinedHours.reduce((total, item) => total + parseFloat(item.hours), 0);

                updatedAllStaff[existingStaffIndex][staffKey] = {
                    sum: newSum.toString(),
                    hours: combinedHours,
                };
            } else {
                updatedAllStaff.push({
                    [staffKey]: {
                        sum: newStaff[staffKey].sum,
                        hours: newHours,
                    },
                });
            }
            return updatedAllStaff;
        }, [...allStaff]);
    };

    return (
        <Modal
            title={`${selectedStaff}`}
            visible={visible}
            onCancel={() => setVisible(false)}
            onOk={() => {
                const updatedStaff = updateAllStaff(staffList, selectedStaff);
                setVisible(false);
                handleInputChange(selectedTask?.id, 'staffHours', updatedStaff);
            }}
        >
            <h2 style={{ marginBottom: '10px', fontSize: '20px' }}> {selectedTask?.phase}</h2>
            <h3 style={{ marginBottom: '10px', fontSize: '16px' }}>Task Name : {selectedTask?.task}</h3>
            <DatePicker
                value={newSubcontractorHours.date ? dayjs(newSubcontractorHours.date) : null}
                onChange={(date, dateString) => setNewSubcontractorHours({ ...newSubcontractorHours, date: dateString })}
                format="YYYY-MM-DD"
                style={{ width: '100%', marginBottom: '10px' }}
            />
            <Input
                type="number"
                placeholder="Hours"
                value={newSubcontractorHours.hours}
                onChange={(e) => setNewSubcontractorHours({ ...newSubcontractorHours, hours: e.target.value })}
                addonAfter="h"
                style={{ marginBottom: '10px' }}
            />
            <Button type="primary" onClick={handleAddSubcontractorHours}>Add</Button>
            <div>
                <ul>
                    {staffList?.length > 0 && staffList?.map((item, index) => (
                        <li key={index}>
                            {Object.keys(item)[0]}:
                            <div>Sum of Hours: {item[Object.keys(item)[0]].sum} hours</div>
                            <ul>
                                {item[Object.keys(item)[0]].hours?.length > 0 && item[Object.keys(item)[0]].hours.map((subItem, subIndex) => (
                                    <li key={subIndex}>
                                        Date: {subItem.date}, Hours: {subItem.hours}
                                        <Button
                                            type="link"
                                            danger
                                            icon={<DeleteOutlined />}
                                            onClick={() => handleDeleteSubcontractorHours(subItem.date)}
                                            style={{ marginLeft: '10px' }}
                                        >

                                        </Button>
                                    </li>
                                ))}
                            </ul>
                        </li>
                    ))}
                </ul>
            </div>
        </Modal>
    );
}

export default StaffCostModal;
