import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Upload, message, Space, InputNumber } from 'antd';
import { DeleteOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import shipyardService from '../../services/shipyardService';

const ShipyardForm = ({ shipyard, onSuccess, onCancel, onShipyardCreated, selectedCurrency }) => {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState(
    shipyard?.logo ? [{ url: shipyard.logo }] : []
  );
  const [staffMembers, setStaffMembers] = useState([]);
  const [logistics, setLogistics] = useState([]);

  const resetForm = () => {
    form.resetFields();
    setStaffMembers([]);
    setLogistics([]);
    setFileList([]);
  };

  useEffect(() => {
    return () => {
      resetForm();
    };
  }, [onCancel]);

  useEffect(() => {
    if (shipyard) {
      // Parse staffMembers
      let parsedStaffMembers = [];
      try {
        if (shipyard.staffMembers && typeof shipyard.staffMembers === 'string') {
          parsedStaffMembers = JSON.parse(shipyard.staffMembers);
        } else if (Array.isArray(shipyard.staffMembers)) {
          parsedStaffMembers = shipyard.staffMembers;
        }
      } catch (error) {
        console.error('Error parsing staffMembers:', error);
      }
      setStaffMembers(parsedStaffMembers);

      // Parse Logistics
      let parsedLogistics = [];
      try {
        if (shipyard.Logistics && typeof shipyard.Logistics === 'string') {
          parsedLogistics = JSON.parse(shipyard.Logistics);
        } else if (Array.isArray(shipyard.Logistics)) {
          parsedLogistics = shipyard.Logistics;
        }
      } catch (error) {
        console.error('Error parsing Logistics:', error);
      }
      setLogistics(parsedLogistics);

      // Set form initial values
      form.setFieldsValue({
        name: shipyard.name,
      });
    }
  }, [shipyard, form]);

  const handleAddStaff = () => {
    const allFilled = staffMembers.every(staff => staff.name && staff.number );
    if (allFilled) {
      setStaffMembers([...staffMembers, { name: '', number: '', cost: '', sell: '' }]);
    } else {
      message.error('Please fill all staff member fields before adding.');
    }
  };

  const handleRemoveStaff = (index) => {
    const newStaffMembers = [...staffMembers];
    newStaffMembers.splice(index, 1);
    setStaffMembers(newStaffMembers);
  };

  const handleStaffChange = (index, field, value) => {
    const newStaffMembers = [...staffMembers];
    newStaffMembers[index][field] = value; // Don't append currency here
    setStaffMembers(newStaffMembers);
  };

  const handleAddLogistics = () => {
    const allFilled = logistics.every(logistic => logistic);
    if (allFilled) {
      setLogistics([...logistics, '']);
    } else {
      message.error('Please fill all logistics fields before adding.');
    }
  };

  const handleRemoveLogistics = (index) => {
    const newLogistics = [...logistics];
    newLogistics.splice(index, 1);
    setLogistics(newLogistics);
  };

  const handleLogisticsChange = (index, value) => {
    const newLogistics = [...logistics];
    newLogistics[index] = value;
    setLogistics(newLogistics);
  };

  const handleSubmit = async (values) => {
    try {
      const validStaffMembers = staffMembers.filter(staff =>
        staff.name && staff.number 
      ).map(staff => ({
        ...staff,
        cost: `${staff.cost} ${selectedCurrency}`,
        sell: `${staff.sell} ${selectedCurrency}`
      }));
      const projectData = {
        name: values.name,
        staffMembers: validStaffMembers,
        Logistics: logistics,
        logo: fileList[0]?.originFileObj,
      };
      if (shipyard) {
        await shipyardService.updateShipyard(shipyard.id, projectData);
        message.success('Shipyard updated successfully');
      } else {
        const response = await shipyardService.createShipyard(projectData);
        onShipyardCreated && onShipyardCreated(response);
        message.success('Shipyard created successfully');
      }
      resetForm();
      onSuccess();
    } catch (error) {
      message.error('Failed to save shipyard');
    }
  };

  const uploadProps = {
    beforeUpload: (file) => {
      const isImage = file.type.startsWith('image/');
      if (!isImage) {
        message.error('You can only upload image files!');
        return false;
      }
      return false;
    },
    onChange: ({ fileList }) => setFileList(fileList),
    fileList,
  };
  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
      initialValues={shipyard}
    >
      <Form.Item
        name="name"
        label="Shipyard Name"
        rules={[{ required: true, message: 'Please enter shipyard name' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="staffNumber"
        label="Staff Members"
      >
        {staffMembers.map((staff, index) => (
          <Space key={index} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
            <Input
              placeholder="Name"
              value={staff.name}
              onChange={(e) => handleStaffChange(index, 'name', e.target.value)}
            />
            <Input
              placeholder="Number"
              value={staff.number}
              onChange={(e) => handleStaffChange(index, 'number', e.target.value)}
              type="number"
            />
            {selectedCurrency && <div style={{ display: 'flex', gap: 8 }}>
              <InputNumber
                placeholder="Cost"
                value={staff.cost}
                onChange={(value) => handleStaffChange(index, 'cost', value)}
                addonAfter={selectedCurrency}
                min={0}
              />
              <InputNumber
                placeholder="Sell"
                value={staff.sell}
                onChange={(value) => handleStaffChange(index, 'sell', value)}
                addonAfter={selectedCurrency}
                min={0}
              />
            </div>}
            <Button type="danger" onClick={() => handleRemoveStaff(index)} icon={<DeleteOutlined />}></Button>
          </Space>
        ))}
        <Button type="dashed" onClick={handleAddStaff} style={{ width: '100%' }} icon={<PlusOutlined />} >Add Staff Member</Button>
      </Form.Item>
      <Form.Item label="Logistics">
        {logistics.map((logistic, index) => (
          <Space key={index} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
            <Input
              placeholder="Logistics Name"
              value={logistic}
              onChange={(e) => handleLogisticsChange(index, e.target.value)}
            />
            <Button type="danger" onClick={() => handleRemoveLogistics(index)} icon={<DeleteOutlined />}></Button>
          </Space>
        ))}
        <Button type="dashed" onClick={handleAddLogistics} style={{ width: '100%' }} icon={<PlusOutlined />}>
          Add Logistics
        </Button>
      </Form.Item>
      <Form.Item
        name="logo"
        label="Logo"
      >
        <Upload {...uploadProps} listType="picture">
          <Button icon={<UploadOutlined />}>Upload Logo</Button>
        </Upload>
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" block>
          {shipyard ? 'Update Shipyard' : 'Create Shipyard'}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ShipyardForm;