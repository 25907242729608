import { Modal } from 'antd'
import React from 'react'
import styled from 'styled-components';


const TaskCard = styled.div`
background: #ffffff;
border: 2px solid ${({ progress }) => (progress == 100 ? '#52c41a' : progress > 0 ? '#faad14' : '#f5222d')};
padding: 8px;
border-radius: 8px;
margin-bottom: 5px;
display: flex;
justify-content: space-between;
align-items: center;
`;
function TaskScheduleModal({ isModalVisible, setIsModalVisible, selectedWeekTasks, selectedDate }) {
    return (
        <Modal
            title={selectedDate?.type === 'day'
                ? `Tasks for ${selectedDate.date}`
                : `Tasks from ${selectedDate?.startOfWeek.format('YYYY-MM-DD')} to ${selectedDate?.endOfWeek.format('YYYY-MM-DD')}`} visible={isModalVisible}
            onCancel={() => setIsModalVisible(false)}
            footer={null}
        >
            {selectedWeekTasks.length > 0 ? (
                selectedWeekTasks.map(task => (
                    <TaskCard key={task.id} progress={task.workComplete || 0}>
                        <div>{task.task}</div>
                        <div>{task.workComplete !== null ? `${Math.round(task.workComplete)}%` : '0%'}</div>
                    </TaskCard>
                ))
            ) : (
                <div>No tasks this week</div>
            )}
        </Modal>
    )
}

export default TaskScheduleModal
