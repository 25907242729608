import React, { useState, useEffect } from 'react';
import { Form, Input, Select, DatePicker, InputNumber, Button, Space, Table, message, Checkbox, Row, Col } from 'antd';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import resourceService from '../../services/resourceService';
import activityService from '../../services/activityService';

const { TextArea } = Input;
const { RangePicker } = DatePicker;
const { Option } = Select;

const ActivityForm = ({ selectedTask, taskHierarchy, activity, activityGroups, projectId, onClose,onRefresh  }) => {
  console.log("Activity",activity);
  console.log("taskHierarchy",taskHierarchy);
  console.log("selectedTask",selectedTask);
  
  const [form] = Form.useForm();
  const [resources, setResources] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedTaskStartDate, setSelectedTaskStartDate] = useState(selectedTask.startDate)
  const [selectedTaskEndDate, setSelectedTaskEndDate] = useState(selectedTask.endDate)

  function formatDate(dateStr) {
    const date = new Date(dateStr); 
    const day = String(date.getDate()).padStart(2, '0'); 
    const month = String(date.getMonth() + 1).padStart(2, '0'); 
    const year = date.getFullYear(); 

    return `${day}-${month}-${year}`;
}
  useEffect(() => {
    setSelectedTaskStartDate(formatDate(selectedTaskStartDate));
    setSelectedTaskEndDate(formatDate(selectedTaskEndDate));
  }, [])
  
  useEffect(() => {
    // fetchAvailableResources();
    form.setFieldsValue({
      group: activityGroups?.groupName,
      subgroup: activityGroups?.subgroup,
    });
    if (activity) {
      form.setFieldsValue({
        itemNumber: activity.itemNumber,
        task: activity.task,
        startDate: moment(activity.startDate),
        endDate: moment(activity.endDate),
        manpower: activity.manpower,
        logistics: Array.isArray(activity?.logistics) ? activity.logistics : [],
      });
    } else {
      form.resetFields(['itemNumber', 'task', 'startDate', 'endDate', 'manpower', 'logistics', 'resources']); // Reset fields when no activity is selected
    }
  }, [activity, form, activityGroups]);

  const fetchAvailableResources = async () => {
    try {
      const data = await resourceService.getResourcesByShipyard(projectId);
      setResources(data);
    } catch (error) {
      message.error('Failed to fetch resources');
    }
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      const activityData = {
        ...values,
        projectId,
        ...(activity?.id ? { id: activity?.id } : {}),
      };
      if (activity?.id) {
        await activityService.updateActivity(activity.id, activityData);
      } else {
        await activityService.createActivity(activityData);
      }
      message.success('Activity saved successfully');
     onRefresh();
      form.resetFields();
      onClose(); // Close the form modal
    } catch (error) {
      console.error(error.message); // Handle error message
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
    >
      <h2>Page for each ITEM chosen</h2>

      {/* <Form.Item
        name="group"
        label="Group"
        rules={[{ required: true, message: 'Please enter the group' }]}
      >
        <Input disabled />
      </Form.Item>

      <Form.Item
        name="subgroup"
        label="Subgroup"
        rules={[{ required: true, message: 'Please enter the subgroup' }]}
      >
        <Input disabled />
      </Form.Item> */}

      <Form.Item
        name="itemNumber"
        label="n° ITEM"
        rules={[{ required: true, message: 'Please enter the item number' }]}
      >
        <InputNumber min={0} style={{ width: '100%' }} />
      </Form.Item>

      <Form.Item
        name="task"
        label="TASK"
      >
        <TextArea rows={4} />
      </Form.Item>

      <Form.Item
        name="startDate"
        label="Start Date"
        rules={[{ required: true, message: 'Please select the start date' }]}
        value={selectedTaskStartDate}
      >
        <DatePicker format="DD/MM/YYYY" value={selectedTaskStartDate} />
      </Form.Item>

      <Form.Item
        name="endDate"
        label="End Date"
        value={selectedTaskEndDate}
        rules={[{ required: true, message: 'Please select the end date' }]}
      >
        <DatePicker format="DD/MM/YYYY" value={selectedTaskEndDate} />
      </Form.Item>

      <Form.Item label="Manpower Need">
        <Form.List name="manpower">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                  <Form.Item
                    {...restField}
                    name={[name, 'role']}
                    rules={[{ required: true, message: 'Select role' }]}
                  >
                    <Select placeholder="Select role" style={{ width: 200 }}>
                      <Option value="Mechanic">Mechanic</Option>
                      <Option value="Fitter">Fitter</Option>
                      <Option value="Welder">Welder</Option>
                      <Option value="Firefighter">Firefighter</Option>
                      <Option value="Rigger">Rigger</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'number']}
                    rules={[{ required: true, message: 'Enter number of personnel' }]}
                  >
                    <InputNumber min={0} placeholder="x" style={{ width: 100 }} />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Add Role
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form.Item>

      <Form.Item label="Logistic Needs">
        <Form.Item name="logistics">
          <Checkbox.Group>
            <Row>
              <Col span={8}><Checkbox value="Staging">Staging</Checkbox></Col>
              <Col span={8}><Checkbox value="Forklift">Forklift</Checkbox></Col>
              <Col span={8}><Checkbox value="Cherry picker">Cherry picker</Checkbox></Col>
              <Col span={8}><Checkbox value="Truck">Truck</Checkbox></Col>
              <Col span={8}><Checkbox value="Manitou">Manitou</Checkbox></Col>
              <Col span={8}><Checkbox value="Crane">Crane</Checkbox></Col>
              <Col span={8}><Checkbox value="Lifting">Lifting</Checkbox></Col>
              <Col span={8}><Checkbox value="Storage">Storage</Checkbox></Col>
            </Row>
          </Checkbox.Group>
        </Form.Item>
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading} block>
          {activity ? 'Update Activity' : 'Create Activity'}
        </Button>
      </Form.Item>
    </Form>

  );
};

export default ActivityForm;