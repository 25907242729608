import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000/api';
const taskTemplateService = {
    async createTaskTemplate(title, groups) {
        const response = await axios.post(`${API_URL}/task-templates`, { title, groups }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        });
        console.log("task template creation response",response.data);
        localStorage.setItem("taskTemplateId", response.data.id)
        return response.data;
    },

    async updateTaskTemplate(id, title, groups) {
        const response = await axios.put(`${API_URL}/task-templates/${id}`, { title, groups }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        });
        return response.data;
    },

    async deleteTaskTemplate(id) {
        await axios.delete(`${API_URL}/task-templates/${id}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        });
    },

    async getTaskTemplates() {
        const response = await axios.get(`${API_URL}/task-templates`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        });
        return response.data;
    },
    
    async getTaskTemplateById(id) {
        const response = await axios.get(`${API_URL}/task-templates/${id}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        });
        return response.data;
    },
    
    async getTaskTemplateByProjectId(id,offset) {
        const response = await axios.get(`${API_URL}/task-templates/project/${id}?offset=${offset}&limit=10`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        });
        return response.data;
    }
};

export default taskTemplateService;