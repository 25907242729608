import axios from 'axios';


const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000/api';



const baselineService = {
    createBaseline : async (data) => {
        const response  = await axios.post(`${API_URL}/baseline`,data,{
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
        return response.data;
    }
}


export default  baselineService;