import React, { useEffect, useState } from 'react';
import { Calendar, Modal, Input, Badge, Button } from 'antd';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isoWeek from 'dayjs/plugin/isoWeek';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import styled from 'styled-components';
import activityService from '../../services/activityService';
import { useSearchParams } from 'react-router-dom';
import 'dayjs/locale/en-gb'; // English locale with Monday as the first day
import locale from 'antd/es/calendar/locale/en_GB'; // Ant Design locale with Monday as the first day
import TaskScheduleModal from '../../components/Modals/TaskScheduleModal';

dayjs.extend(isBetween);
dayjs.extend(isoWeek);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

const TaskCard = styled.div`
  background: #f0f2f5;
  border: 2px solid ${({ progress }) => (progress === 100 ? '#52c41a' : progress > 0 ? '#faad14' : '#f5222d')};
  padding: 8px;
  border-radius: 8px;
  margin-bottom: 5px;
`;

const DeliverablesSchedule = () => {
  const [tasks, setTasks] = useState([]);
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get('projectId');
  const [projectStartDate, setProjectStartDate] = useState(null);
  const [selectedWeekTasks, setSelectedWeekTasks] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [searchWeek, setSearchWeek] = useState('');
  const [calendarWeeks, setCalendarWeeks] = useState([]);
  const [cellHeight, setCellHeight] = useState(0); // Store the height of one cell
  const [weekRowStyle, setWeekRowStyle] = useState({})
  const [selectedDate, setSelectedDate] = useState(null);
  const [firstTaskDate, setFirstTaskDate] = useState(null);
  const [lastTaskDate, setLastTaskDate] = useState(null);
  const [mode, setMode] = useState('month');

  const getTaskTemplate = async () => {
    try {
      const activities = await activityService.getAllActivitiesByProject(projectId);
      const newTasks = activities.activities.filter(task => task.outlineLevel > 2);
      const earliestStartDate = newTasks.reduce((earliest, task) =>
        dayjs(task.startDate).isBefore(dayjs(earliest)) ? task.startDate : earliest,
        newTasks[0]?.startDate
      );
      setProjectStartDate(dayjs(earliestStartDate).startOf('isoWeek'));
      setTasks(newTasks);

      if (newTasks.length > 0) {
        const firstTask = getFirstTaskDate(newTasks);
        setFirstTaskDate(firstTask);
        const lastTask = getLastTaskDate(newTasks);
        setLastTaskDate(lastTask);
      }

    } catch (error) {
      console.error('Error fetching assigned task template:', error);
    }
  };

  const getLastTaskDate = (tasks) => {
    const lastTask = tasks.reduce((latest, task) => {
      const taskEndDate = dayjs(task.endDate); // or use task.startDate if you prefer
      return taskEndDate.isAfter(latest) ? taskEndDate : latest;
    }, dayjs('2020-01-01')); // Set a very early date as the initial value

    return lastTask;
  };
  const getFirstTaskDate = (tasks) => {
    const firstTask = tasks.reduce((earliest, task) => {
      const taskStartDate = dayjs(task.startDate); // Use task.startDate
      return taskStartDate.isBefore(earliest) ? taskStartDate : earliest;
    }, dayjs('9999-12-31')); // Set a very far future date as the initial value

    return firstTask;
  };
  useEffect(() => {
    getTaskTemplate();
  }, []);

  useEffect(() => {
    if (projectStartDate) {
      generateCalendarWeeks(dayjs());
    }
  }, [projectStartDate]);

  const getListData = (value) => {
    const listData = tasks
      .filter(task =>
        dayjs(task.startDate).isSameOrBefore(value, 'day') &&
        dayjs(task.endDate).isSameOrAfter(value, 'day')
      )
      .map(task => ({
        type: task.workComplete == 100 ? 'success' : task.workComplete > 0 ? 'warning' : 'error',
        content: task.task,
      }));

    return listData || [];
  };

  const getWeekNumber = (date) => {
    if (!projectStartDate) return 0;
    return dayjs(date).diff(projectStartDate, 'week') + 1;
  };

  const getWeekRange = (date) => {
    const startOfWeek = dayjs(date).startOf('isoWeek');
    const endOfWeek = dayjs(date).endOf('isoWeek');
    return { startOfWeek, endOfWeek };
  };

  const handleCellClick = (value) => {
    const selectedDate = dayjs(value).startOf('day'); // Get the clicked day at midnight
    const filteredTasks = tasks.filter(task =>
      dayjs(task.startDate).isSameOrBefore(selectedDate, 'day') && dayjs(task.endDate).isSameOrAfter(selectedDate, 'day')
    );
    setSelectedWeekTasks(filteredTasks); // Set tasks that are active on the clicked day
    setSelectedDate({ type: 'day', date: selectedDate.format('YYYY-MM-DD') });
    setIsModalVisible(true);
  };

  const dateCellRender = (value) => {
    const listData = getListData(value);
    if (!projectStartDate) return null;

    const { startOfWeek } = getWeekRange(value);
    if (dayjs(startOfWeek).isBefore(projectStartDate)) return null;

    return (
      <div onClick={() => handleCellClick(value)} style={{ cursor: 'pointer' }}>
        <ul className="events">
          {listData.map((item) => (
            <li key={item.content}>
              <Badge status={item.type} text={item.content} />
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const generateCalendarWeeks = (value) => {
    const startOfCalendarMonth = dayjs(value).startOf('month').startOf('isoWeek');
    const weeks = Array.from({ length: 6 }, (_, i) => getWeekNumber(startOfCalendarMonth.add(i, 'week')));
    setCalendarWeeks(weeks);
  };

  const handleSearch = (e) => {
    const value = e.target.value.trim();
    const weekNumber = value ? parseInt(value, 10) : '';
    setSearchWeek(weekNumber);
  };

  const filteredSearchTasks = searchWeek
    ? tasks.filter(task => {
      const { startOfWeek, endOfWeek } = getWeekRange(projectStartDate.add(searchWeek - 1, 'week'));
      return (
        dayjs(task.startDate).isSameOrBefore(endOfWeek, 'day') &&
        dayjs(task.endDate).isSameOrAfter(startOfWeek, 'day')
      );
    })
    : [];

  const handleWeekClick = (weekNumber) => {
    const { startOfWeek, endOfWeek } = getWeekRange(projectStartDate.add(weekNumber - 1, 'week'));
    const filteredTasks = tasks.filter(task =>
      dayjs(task.startDate).isSameOrBefore(endOfWeek, 'day') &&
      dayjs(task.endDate).isSameOrAfter(startOfWeek, 'day')
    );
    setSelectedWeekTasks(filteredTasks);
    setSelectedDate({ type: 'week', startOfWeek, endOfWeek });
    setIsModalVisible(true);
  };

  useEffect(() => {
    // Function to get the height of one calendar cell
    const getCellHeight = () => {
      const calendarCell = document.querySelector('.ant-picker-cell'); // Select the first calendar cell
      if (calendarCell) {
        const height = calendarCell.clientHeight;
        setCellHeight(height); // Store the height of the first cell
      }
    };

    // Delay to ensure cells are rendered
    const timeoutId = setTimeout(() => {
      getCellHeight();
    }, 0);

    // Cleanup
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    if (cellHeight) {
      setWeekRowStyle({
        height: `${cellHeight}px`, // Apply the captured height to table rows
      });
    }
  }, [cellHeight]);

  const handlePanelChange = (value, mode) => {
    setMode(mode);
  };

  return (
    <div style={{ padding: '20px' }}>
      <h1>Deliverables Schedule</h1>
      <Input
        placeholder="Search by Week Number"
        onChange={handleSearch}
        value={searchWeek}
        style={{ marginBottom: '20px' }}
      />
      {searchWeek !== '' && (
        filteredSearchTasks.length > 0 ?
          <div>
            <h3>Tasks in Week {searchWeek}</h3>
            {filteredSearchTasks.map(task => (
              <TaskCard key={task.id} progress={task.workComplete || 0}>
                <div>{task.task}</div>
                <div>{task.workComplete !== null ? `${task.workComplete}%` : '0%'}</div>
              </TaskCard>
            ))}
          </div>
          : <h3> No Tasks in Week </h3>
      )}
      <div style={{ height: '100%', display: 'flex', gap: '10px' }}>
        {mode === 'month' && (
          <div style={{ backgroundColor: 'white', border: '1px solid #000000' }}>
            <h3 style={{ textAlign: 'center', fontSize: '24px', padding: '8px' }}>Weekly Schedule</h3>
            <table >
              <tbody>
                {calendarWeeks.map((week, index) => (
                  <tr key={index} style={{ cursor: 'pointer', ...weekRowStyle }}>
                    <td style={{ textAlign: 'center ' }}>
                      <Button style={{ marginLeft: '15px' }} type="primary" onClick={() => handleWeekClick(week)}>
                        Week {week}
                      </Button></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        <Calendar
          onChange={generateCalendarWeeks}
          locale={locale}
          dateCellRender={dateCellRender}
          style={{ height: '100%' }}
          onPanelChange={handlePanelChange}
          validRange={lastTaskDate && firstTaskDate && [
            dayjs(firstTaskDate).startOf('year'),
            dayjs(lastTaskDate).endOf('year')
          ]}
        />
      </div>
      <TaskScheduleModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        selectedWeekTasks={selectedWeekTasks}
        selectedDate={selectedDate}
      />
    </div>
  );
};

export default DeliverablesSchedule;
