import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import Dashboard from '../pages/Dashboard';
import Login from '../pages/Auth/Login';
import Projects from '../pages/Projects';
import Shipyards from '../pages/Shipyards';
import ProjectDetails from '../pages/Projects/ProjectActivities';
import TaskTemplates from '../pages/TaskTemplates.js';
import Test from '../pages/Test/index.js';
import ForgotPassword from '../pages/Auth/ForgotPassword.js';
import ResetPassword from '../pages/Auth/ResetPassword.js';
import DailyInput from '../pages/DailyInput/index.js';
import DeliverablesSchedule from '../pages/DeliverablesSchedule/index.js';
import CostEstimates from '../pages/Costs/index.js';

const PrivateRoute = ({ children }) => {
  const { user, loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  return user ? children : <Navigate to="/login" />;
};

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route
        path="/dashboard"
        element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        }
      />
      <Route
        path="/projects"
        element={
          <PrivateRoute>
            <Projects />
          </PrivateRoute>
        }
      />
      <Route
        path="/projects/:id"
        element={
          <PrivateRoute>
            <ProjectDetails />
          </PrivateRoute>
        }
      />
      <Route
        path="/shipyards"
        element={
          <PrivateRoute>
            <Shipyards />
          </PrivateRoute>
        }
      />
      <Route
        path="/task-templates"
        element={
          <PrivateRoute>
            <TaskTemplates />
          </PrivateRoute>
        }
      />
      <Route
        path="/costs/:id"
        element={
          <PrivateRoute>
            <CostEstimates />
          </PrivateRoute>
        }
      />
      <Route
        path="/test"
        element={
          <PrivateRoute>
            <Test />
          </PrivateRoute>
        }
      />
      <Route
        path="/daily-input"
        element={
          <PrivateRoute>
            <DailyInput />
          </PrivateRoute>
        }
      />
      <Route
        path="/deliverables-schedule"
        element={
          <PrivateRoute>
            <DeliverablesSchedule />
          </PrivateRoute>
        }
      />
      {/* <Route
        path="/gantt-chart"
        element={
          <PrivateRoute>
            <DailyInput />
          </PrivateRoute>
        }
      /> */}
      <Route path="/" element={<Navigate to="/projects" />} />
    </Routes>
  );
};

export default AppRoutes;