import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import AppRoutes from './routes';
import { AuthProvider } from './context/AuthContext';
import { jiraTheme } from './theme';
import { ActivityProvider } from './context/ActivityContext';

function App() {
  return (
    <ConfigProvider theme={jiraTheme}>
      <AuthProvider>
        <ActivityProvider>
          <Router>
            <AppRoutes />
          </Router>
        </ActivityProvider>
      </AuthProvider>
    </ConfigProvider>
  );
}

export default App;