import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000/api';

const resourceService = {
  getResourcesByShipyard: async (shipyardId) => {
    const response = await axios.get(`${API_URL}/resources/shipyard/${shipyardId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
    return response.data;
  },

  createResource: async (resourceData) => {
    const response = await axios.post(`${API_URL}/resources`, resourceData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
    return response.data;
  },

  updateResource: async (id, resourceData) => {
    const response = await axios.put(`${API_URL}/resources/${id}`, resourceData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
    return response.data;
  },

  deleteResource: async (id) => {
    const response = await axios.delete(`${API_URL}/resources/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
    return response.data;
  },

  // Additional resource-related services
  getResourceAvailability: async (resourceId, startDate, endDate) => {
    const response = await axios.get(`${API_URL}/resources/${resourceId}/availability`, {
      params: { startDate, endDate },
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
    return response.data;
  },

  assignResource: async (resourceId, projectId, activityId, hours) => {
    const response = await axios.post(
      `${API_URL}/resources/${resourceId}/assign`,
      {
        projectId,
        activityId,
        hours
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      }
    );
    return response.data;
  },

  unassignResource: async (resourceId, projectId, activityId) => {
    const response = await axios.post(
      `${API_URL}/resources/${resourceId}/unassign`,
      {
        projectId,
        activityId
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      }
    );
    return response.data;
  },

  getResourceMetrics: async (resourceId, startDate, endDate) => {
    const response = await axios.get(`${API_URL}/resources/${resourceId}/metrics`, {
      params: { startDate, endDate },
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
    return response.data;
  }
};

export default resourceService;